<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.slim]="slimNavBar">
  <a class="navbar-brand" routerLink="/">
    <img src="assets/reza-logo.svg" height="40" alt="ReZA Logo"/>
  </a>

  <ul class="navbar-nav mr-auto" *ngIf="user">
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/studies">{{ 'STUDY.studies' | transloco }}</a>
    </li>
    <li class="nav-item" routerLinkActive="active" *ngIf="user.roles?.includes('admin')">
      <a class="nav-link" routerLink="/admin">Admin-Panel</a>
    </li>
  </ul>

  <ul class="navbar-nav" *ngIf="user">
    <li class="navbar-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/user', user.id]"><fa-icon icon="user"></fa-icon> {{user.name}}</a>
    </li>
    <li class="nav-item">
      <a href="#" class="nav-link" (click)="logout()"><fa-icon icon="sign-out-alt"></fa-icon> Logout</a>
    </li>
  </ul>

  <ul class="navbar-nav" [class.ml-auto]="!user">
    <li class="navbar-item dropdown language" dropdown>
      <a class="nav-link dropdown-toggle text-uppercase cursor-pointer"
         dropdownToggle>
        {{ activeLang }}
      </a>
      <div class="dropdown-menu" *dropdownMenu>
        <a class="dropdown-item text-uppercase cursor-pointer"
           *ngFor="let lang of availableLang"
           (click)="setActiveLang(lang)">
          {{ lang }}
        </a>
      </div>
    </li>
  </ul>

  <ul class="navbar-nav">
    <li class="navbar-text pl-2 small">v0.10.1</li>
  </ul>

</nav>
