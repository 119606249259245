<ng-container *transloco="let t">

  <button class="btn btn-secondary btn-sm" (click)="openModal(template)">
    <fa-icon icon="bullseye"></fa-icon> {{ t('TARGET.showConstruct') }}
  </button>

  <ng-template #template>
    <div class="modal-header">
       <h4 class="modal-title pull-left">{{ t('TARGET.construct') }}</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: 600px;" >
      <graph-editor filterByType="target"></graph-editor>
    </div>
  </ng-template>

</ng-container>
