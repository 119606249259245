import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../core/auth/auth.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    user: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  submitted = false;

  constructor(private router: Router,
              private authService: AuthService,
              private notify: NotificationsService) { }

  ngOnInit() {
  }

  login() {
    this.submitted = true;

    if (this.loginForm.valid) {

      this.authService.login(this.loginForm.value.user, this.loginForm.value.password)
        .subscribe((res) => {
          console.log('User logged in', res);
          this.router.navigate(['/studies']);
        }, (error) => {
          this.notify.error('Anmeldung fehlgeschlagen!');
          console.error(error);
        });

    } else {

      this.notify.warn('Alle Felder ausfüllen!');

    }
  }

}
