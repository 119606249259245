import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteParserService {

  constructor(private router: Router) { }

  getParam(key: string): string {

    const regexpKey = new RegExp(':' + key);

    function traverseRouteSnapshot(snapshot: ActivatedRouteSnapshot): string {

      if (snapshot.routeConfig && regexpKey.test(snapshot.routeConfig.path)) {
        return snapshot.paramMap.get(key);
      }

      for (const child of snapshot.children) {
        const result = traverseRouteSnapshot(child);
        if (result) {
          return result;
        }
      }

      return undefined;

    }

    return traverseRouteSnapshot(this.router.routerState.snapshot.root);

  }

}


