import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { AuthService } from '../auth/auth.service';
import { User } from '@data/schema/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private loggedInSubscription: Subscription;

  user: User;
  slimNavBar = false;

  activeLang = 'de';
  availableLang = ['de'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {

    this.loggedInSubscription = this.authService.loggedIn$.subscribe(() => {
      this.user = this.authService.user;
    });

    // slim navbar when in study route
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.slimNavBar = /\/studies\/\w+/.test(event.url);
      }
    });

    this.availableLang = this.translocoService.getAvailableLangs() as string[];
    this.activeLang = this.translocoService.getActiveLang();

  }

  logout() {
    this.authService.logout();
  }

  setActiveLang(lang: string) {
    this.activeLang = lang;
    moment.locale(lang);
    this.translocoService.setActiveLang(lang);
    localStorage.setItem('lang', lang);
  }

  ngOnDestroy() {
    this.loggedInSubscription.unsubscribe();
  }

}
