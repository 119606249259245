import {Component, ElementRef, OnInit} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';
import {FileItem, FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.sass']
})
export class FileUploadModalComponent implements OnInit {

  onClose = new Subject<boolean>();

  config: FileUploaderOptions = {};
  title = 'Datei hochladen';

  uploader: FileUploader;
  fileItem: FileItem;
  hasFileOverDropZone;

  constructor(private modalRef: BsModalRef,
              private notify: NotificationsService,
              private elementRef: ElementRef) {

    this.uploader = new FileUploader({
      authToken: 'Bearer ' + localStorage.getItem('id_token'),
      itemAlias: 'uploadFile',
      queueLimit: 1,
      allowedFileType: []
    });
  }

  ngOnInit() {
    console.log(this.config);

    this.uploader.setOptions(this.config);

    this.uploader.onWhenAddingFileFailed = (fileItem, filter) => {
      switch (filter.name) {
        case 'queueLimit':
          this.uploader.clearQueue();
          // @ts-ignore
          this.uploader.addToQueue([fileItem]);
          break;
        case 'fileType':
          this.notify.warn('Nicht unterstütztes Dateiformat!');
          break;
        default:
          this.notify.error('Unbekannter Fehler! Siehe Console.');
      }

      console.warn('onWhenAddingFileFailed', fileItem, filter);
    };

    this.uploader.onAfterAddingFile = (fileItem) => {
      console.log(fileItem);
      this.fileItem = fileItem;
    };

    this.uploader.onSuccessItem = () => {
      this.notify.success('Upload erfolgreich!');
      this.onClose.next(true);
      this.modalRef.hide();
    };

    this.uploader.onErrorItem = (fileItem, response, status) => {
      this.notify.error('Etwas lief schief!');
      console.error('onErrorItem', fileItem, response, status);
    };

  }

  openFileDialog() {
    this.elementRef.nativeElement.querySelector('[ng2FileSelect]').click();
  }

  close() {
    this.onClose.next(false);
    this.modalRef.hide();
  }

}
