import { Component, Input } from '@angular/core';
import { Meta } from '@data/schema/meta';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'meta-tooltip',
  templateUrl: './meta-tooltip.component.html',
  styleUrls: ['./meta-tooltip.component.sass']
})
export class MetaTooltipComponent {

  @Input() meta: Meta;
  @Input() prefix = 'editedBy';

}
