<div class="panel-header {{titleSize}}" [class.icon-header]="iconSrc">
  <div>
    <img *ngIf="iconSrc" [src]="iconSrc" [alt]="iconAlt">
    {{title}}
  </div>
  <div>
    <ng-content select=".header-right"></ng-content>
  </div>
</div>

<div [class.panel-body]="!noBorder" [class.p-0]="noPadding">

  <ng-content></ng-content>

</div>
