import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
  pure: false
})
export class FilterArrayPipe implements PipeTransform {

  transform(input: any, key: string, value: any): any {
    if (Array.isArray(input)) {
      return input.filter(item => item[key] === value);
    } else {
      return input;
    }
  }

}
