<div class="container py-3" *transloco="let t; read: 'HOME'">

	<div class="jumbotron">
		<h1 class="display-4">{{ t('rezaMethod') }}</h1>
		<hr class="my-4"/>

		<p class="rivad-full mb-5" [innerHTML]="t('rezaFull')"></p>

    <guide-text guideId="home-about" box="Über ReZA"></guide-text>

	</div>

	<div *ngIf="!isLoggedIn" class="jumbotron d-flex justify-content-center">
		<div class="login-group">
			<app-login></app-login>
		</div>
		<div class="separator"></div>
		<div class="register-group">
			<app-register></app-register>
		</div>
	</div>

  <div class="jumbotron">

    <guide-text guideId="home-usage"></guide-text>

  </div>

</div>
