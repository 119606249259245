<button type="button" class="btn btn-primary" (click)="openModal(template)">{{buttonTitle}}</button>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{modalTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <ng-container *ngComponentOutlet="contentComponent; injector: contentInjector"></ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">Schließen</button>
  </div>
</ng-template>
