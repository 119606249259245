import { Vector2 } from 'three';
import { GraphMergeNode } from '@shared/utils/graph-merge-node';
import { NodeData } from '@data/schema/graph';
import { Meta } from '@data/schema/meta';
import { Finding, FindingType } from '@data/schema/finding';
import { ExplicitPhenomenon, ImplicitPhenomenon } from '@data/schema/phenomenon';

export class GraphNode extends Vector2 implements NodeData {

  id!: string;
  type!: string[];
  data: {
    type?: FindingType,
    variable?: string,
    value?: string,
    gist?: string,
    phenomenon?: ExplicitPhenomenon | ImplicitPhenomenon,
    finding?: Finding
  };
  meta: Meta;
  parent?: GraphMergeNode;
  initialized?: boolean;
  nodeHeight?: number;

  constructor(data: NodeData) {

    super();
    Object.assign(this, data);

  }

}
