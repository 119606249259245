import { Component, ElementRef, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { RouteParserService } from '@shared/services/route-parser.service';
import { RestGraphLink, RestGraphNode } from '@data/schema/graph';
import { LatentVariable } from '@data/schema/latent-variable';
import { TargetVariable } from '@data/schema/target-variable';
import { ExplicitPhenomenon, ImplicitPhenomenon } from '@data/schema/phenomenon';
import { Gist } from '@data/schema/gist';
import { TextSegment } from '@data/schema/text-segment';
import { TextDocument } from '@data/schema/text-document';

@Component({
  selector: 'graph-source-popover',
  templateUrl: './graph-source-popover.component.html',
  styleUrls: ['./graph-source-popover.component.sass']
})
export class GraphSourcePopoverComponent {

  @HostBinding('class.d-block') private active = false;
  @HostBinding('style.top.px') private top = 10;
  @HostBinding('style.left.px') private left = 10;

  source: {
    id: string;
    type: string[];
    latentVariable?: LatentVariable;
    targetVariable?: TargetVariable;
    phenomenon?: ExplicitPhenomenon|ImplicitPhenomenon;
    gist?: Gist;
    segment?: TextSegment;
    document?: TextDocument;
  };
  emptyType = '';

  constructor(
    private element: ElementRef<HTMLElement>,
    private routeParser: RouteParserService,
    private router: Router
  ) { }

  /**
   * Query source of node/link and show popover with respect to position of mouse event.
   */
  async show(d: RestGraphNode|RestGraphLink, event: MouseEvent | PointerEvent): Promise<void> {

    this.source = null;
    this.emptyType = '';

    this.left = -999;
    this.top = -999;

    // @ts-ignore
    this.source = await d.customGET('source').toPromise();

    if (!this.source) { return; }

    if (!this.source.type.includes('Finding')) {
      this.emptyType = this.source.type.find(t => t === 'target' || t === 'latent');
    }

    // if all empty, show nothing
    if (!(this.emptyType || this.source.gist || this.source.targetVariable || this.source.latentVariable)) {
      return;
    }

    this.active = true;

    // set position after content has been set to compute element size
    setTimeout(() => {
      const parentRect = this.element.nativeElement.parentElement.getBoundingClientRect();
      this.left = Math.min(
        event.pageX - parentRect.left,
        this.element.nativeElement.parentElement.offsetWidth - this.element.nativeElement.offsetWidth - 1
      );
      this.top = Math.min(
        event.pageY - parentRect.top,
        this.element.nativeElement.parentElement.offsetHeight - this.element.nativeElement.offsetHeight - 1
      );
    });

  }

  /**
   * Hide popover.
   */
  hide(): void {

    this.active = false;

  }

  /**
   * Navigate to route that is composed with respect to source and type.
   */
  followLink(type: string) {

    const studyId = this.routeParser.getParam('studyId');

    const route = ['/', 'studies', studyId];

    switch (type) {
      case 'segment':
        route.push('analysis', 'document', this.source.document.id, 'segment');
        break;
      case 'gist':
        route.push('analysis', 'document', this.source.document.id, 'segment', this.source.segment.id, 'phenomena');
        break;
      case 'phenomenon':
        route.push('analysis', 'document', this.source.document.id, 'segment', this.source.segment.id, 'derivation');
        break;
      case 'target':
        route.push('preparation', 'target-variables');
        break;
      case 'latent':
        route.push('postprocessing', 'rules');
    }

    this.router.navigate(route);

  }

}
