import * as shortId from 'shortid';
import { Vector2 } from 'three';
import { GraphNode } from './graph-node';

/**
 * Special node that contains nodes of the same finding variable, but different finding values.
 */
export class GraphMergeNode<T extends GraphNode = GraphNode> extends Vector2 {

  id: string;
  nodes: T[] = [];
  type: string[];

  constructor(...nodes: T[]) {

    super();

    this.id = 'merge_' + shortId.generate();
    this.type = [...nodes[0].type];
    nodes.forEach(node => this.addNode(node));

  }

  /**
   * Add node to MergeNode.
   */
  addNode(node: T): void {

    this.nodes.push(node);
    node.parent = this;

    ({x: this.x, y: this.y} = this.nodes.reduce((acc, curr, index, arr) => ({
      x: acc.x + curr.x / arr.length,
      y: acc.y + curr.y / arr.length
    }), {x: 0, y: 0}));

  }

  /**
   * Remove node from MergeNode.
   */
  removeNode(node: T): T | undefined {

    const index = this.nodes.findIndex(n => n.id === node.id);

    if (index > -1) {
      delete node.parent;
      return this.nodes.splice(index, 1)[0];
    }

  }

}
