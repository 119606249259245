import { LinkData } from '@data/schema/graph';
import { Meta } from '@data/schema/meta';
import { GraphNode } from './graph-node';

export class GraphLink implements LinkData {

  id!: string;
  source!: GraphNode;
  target!: GraphNode;
  type: string[];
  meta: Meta;

  constructor(data: LinkData) {

    Object.assign(this, data);

  }

}
