import * as d3 from 'd3';
import { fillParentContainer } from 'fancy-textfill';

export enum DragStartType {
  Incoming = -1,
  Outgoing = 1
}

export class D3jsUtils {

  /**
   * Get position and angle on path, t -> [0,1]
   */
  static computePositionOnPath(path: SVGPathElement, t: number = 0.5) {
    const length = path.getTotalLength();
    const p0 = path.getPointAtLength(t * length);
    const p1 = path.getPointAtLength((t + 0.05) * length);

    return {
      x: p0.x,
      y: p0.y,
      angle: Math.atan2(p1.y - p0.y, p1.x - p0.x) * 180 / Math.PI
    };
  }

  static wrapText(nodeElement) {
    nodeElement.each(function() {
      const node = d3.select(this);
      const text = node.select('text.finding-variable');
      const rectBBox = (node.select('rect.finding-variable').node() as SVGGraphicsElement).getBBox();
      const width = rectBBox.width;
      const height = rectBBox.height;
      const title = text.text();
      const words = title.split(/\s+/).reverse();

      const lineHeight = 1.2; // em
      const fontSize = +text.style('font-size').match(/(\d+)/)[1]; // px

      let word;
      let line = [];
      let lineNumber = 0;
      let tspan = text.text(null).append('tspan')
        .attr('x', width / 2 + rectBBox.x + 'px')
        .attr('dy', rectBBox.y + 'px')
        .attr('text-anchor', 'middle');

      // tslint:disable-next-line:no-conditional-assignment
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          if ((lineNumber + 2) * lineHeight * fontSize > height) {
            tspan.text(line.join(' ') + '...');
            break;
          } else {
            tspan.text(line.join(' '));
            line = [word];
            tspan = text.append('tspan')
              .attr('x', width / 2 + rectBBox.x + 'px')
              .attr('dy', ++lineNumber * lineHeight * fontSize + rectBBox.y + 'px')
              .attr('text-anchor', 'middle')
              .text(word);
          }
        }
      }

      const textHeight = (lineNumber + 1) * lineHeight * fontSize;
      const padding = textHeight < height ? (height - textHeight) / 2 : 0;
      text.selectAll('tspan').attr('y', padding + 'px');
      node.append('title').text(title);
    });
  }

  /**
   * Resize text to fit the container.
   */
  static fitText(element: HTMLElement|SVGElement, options?: {maxWidth?: number, maxHeight?: number, multiline?: boolean}) {
    const config = {
      maxFontSize: 16
    };

    if (options) {
      Object.assign(config, options);
    }

    fillParentContainer(element as HTMLElement, config);
  }

  /**
   * Snap value to nearest grid point definded by resolution
   */
  static roundToResolution(value: number, resolution: number) {
    return value % resolution / 2 ? value - (value % resolution) : value + resolution - (value % resolution);
  }

  /**
   * Copy computed styles of original nodes as inline styles to the copied nodes
   */
  static copyInlineStyles(origList: NodeList, copyList: NodeList) {
    for (let i = 0, l = origList.length; i < l; i++) {
      if (!(origList[i] instanceof SVGElement || origList[i] instanceof HTMLElement)) {
        continue;
      }

      const origElement = origList[i] as SVGElement | HTMLElement;
      const copyElement = copyList[i] as SVGElement | HTMLElement;

      const styles = window.getComputedStyle(origElement);
      for (let j = 0, k = styles.length; j < k; j++) {
        const key = styles[j];

        // skip transform, it is already set as attribute (causes different behaviours of browsers)
        if (key === 'transform') {
          continue;
        }

        const value = styles.getPropertyValue(key);
        copyElement.style.setProperty(key, value);
      }

      D3jsUtils.copyInlineStyles(origElement.childNodes, copyElement.childNodes);
    }
  }

}
