<fa-icon icon="info-circle-regular"
         *ngIf="meta"
         [tooltip]="metaTemplate"
         delay="500" container="body">
</fa-icon>

<ng-template #metaTemplate>
  <div *transloco="let t; read: 'META'">
    {{ t(prefix) }} {{ meta.userName }}<br/>
    {{ meta.timestamp | amLocal | amCalendar }}
  </div>
</ng-template>
