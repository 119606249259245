import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { User } from '@data/schema/user';


interface AuthToken {
  idToken: string;
  expiresIn: number;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Observable that fires when loggedIn status changes.
   */
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(AuthService.isLoggedIn());

  /**
   * User data.
   */
  public user: User;

  /**
   * Observables that emits user data on page load or login/logout.
   */
  public user$ = new ReplaySubject<User>(1);

  constructor(private http: HttpClient) {

    // get user data if logged in
    if (AuthService.isLoggedIn()) {

      this.whoAmI().subscribe(user => {
        this.user = user;
        this.user$.next(user);
        this.loggedIn$.next(true);
      });

    }

  }

  static getExpiration(): moment.Moment {
    const expiration = localStorage.getItem('expires_at');
    return moment(JSON.parse(expiration));
  }

  static isLoggedIn(): boolean {
    return moment().isBefore(AuthService.getExpiration());
  }

  static isLoggedOut(): boolean {
    return !AuthService.isLoggedIn();
  }

  /**
   * Login user by requesting auth token.
   */
  login(user: string, password: string): Observable<AuthToken> {

    return this.http.post<AuthToken>('/api/login', { user, password })
      .pipe(
        tap(result => { this.setSession(result); }),
        shareReplay()
      );

  }

  /**
   * Register new user and request auth token.
   */
  register(email: string, name: string, password: string): Observable<AuthToken> {

    return this.http.post<AuthToken>('/api/register', { email, name, password })
      .pipe(
        tap(result => { this.setSession(result); }),
        shareReplay()
      );

  }

  /**
   * Set session with requested auth token, set localStorage, and get user data.
   * @param authResult Requested auth token
   * @private
   */
  private setSession(authResult: AuthToken): void {

    const expiresAt = moment().add(authResult.expiresIn, 'seconds');

    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

    this.whoAmI().subscribe(user => {
      this.user = user;
      this.user$.next(user);
      this.loggedIn$.next(true);
    });

  }

  /**
   * Logout user and clear localStorage.
   */
  logout(): void {

    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    this.user = undefined;
    this.user$.next();
    this.loggedIn$.next(false);

  }

  /**
   * Get user data.
   */
  whoAmI(): Observable<User> {

    const idToken = localStorage.getItem('id_token');

    return this.http.get<User>('/api/whoami', {
      headers: { Authorization: `Bearer ${idToken}` }
    });

  }

}
