import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../../core/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {

  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  });
  submitted = false;

  constructor(private router: Router,
              private authService: AuthService,
              private notify: NotificationsService) { }

  ngOnInit() {
  }

  register() {
    this.submitted = true;

    if (this.registerForm.valid) {

      this.authService.register(this.registerForm.value.email, this.registerForm.value.name, this.registerForm.value.password)
        .subscribe((res) => {
          console.log('User registered', res);
          this.router.navigate(['/studies']);
        }, error => {
          this.notify.error('Registierung fehlgeschlagen!');
          console.error(error);
        });

    } else {

      this.notify.warn('Füllen Sie alle Felder korrekt aus!');

    }
  }

}
