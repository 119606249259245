import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestangularModule, RestProvider } from 'ngx-restangular-typed';


export function RestangularConfigFactory(RestangularProvider: RestProvider) {
  RestangularProvider.setBaseUrl('api');

  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers) => {

    const idToken = localStorage.getItem('id_token');

    if (idToken) {
      return {
        headers: Object.assign({}, headers, {Authorization: `Bearer ${idToken}`})
      };
    } else {
      return {};
    }

  });
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RestangularModule.forRoot(RestangularConfigFactory)
  ]
})
export class DataModule { }
