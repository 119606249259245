import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

import { FindingService } from '@data/service/finding.service';
import { RestFinding } from '@data/schema/finding';
import { ConfirmModalService } from '@shared/services/confirm-modal.service';

const findingValues = [
  ['VALUE.fact.0', 'VALUE.fact.1', 'VALUE.fact.2'],
  ['VALUE.level.0', 'VALUE.level.1', 'VALUE.level.2'],
  ['VALUE.incline.0', 'VALUE.incline.1', 'VALUE.incline.2'],
  ['VALUE.amount.0', 'VALUE.amount.1', 'VALUE.amount.2'],
  ['VALUE.impact.0', 'VALUE.impact.1', 'VALUE.impact.2']
];

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'finding',
  templateUrl: './finding.component.html',
  styleUrls: ['./finding.component.sass']
})
export class FindingComponent implements OnInit {

  @Input() editable = false;
  @Input() deletable = true;
  @Input() type: string;
  @Input() typeId: string;

  private dataValue: RestFinding;

  public inEditMode = false;
  public findingValues: string[];

  public findingForm = new FormGroup({
    variable: new FormControl('', Validators.required),
    value: new FormControl('', Validators.required)
  });

  public variablesSource$: Observable<string[]>;

  @Output() dataChange = new EventEmitter<RestFinding>();
  @Output() saved = new EventEmitter<RestFinding>();
  @Output() removed = new EventEmitter<RestFinding>();
  @Output() modeChange = new EventEmitter<boolean>();

  @Input()
  get data(): RestFinding {
    return this.dataValue;
  }

  set data(val: RestFinding) {
    this.dataValue = val;
    this.dataChange.emit(this.dataValue);
  }

  constructor(
    private findingService: FindingService,
    private notify: NotificationsService,
    private confirmModal: ConfirmModalService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    // create grouped select options list
    this.findingValues = findingValues.reduce((acc, currentValue, index) => {
      if (index !== 0) {
       acc.push(null);
      }
      return acc.concat(currentValue);
    }, []);

    // typeahead observable
    this.variablesSource$ = new Observable((observer: Observer<string>) => {
      observer.next(this.findingForm.controls.variable.value);
    }).pipe(
      mergeMap((token: string) => this.findingService.queryVariable(token))
    );

    // if there is no item, enter edit mode to create new finding
    if (!this.data || !this.data.variable) {
      this.enterEditMode();
    }

    if (this.type || this.data?.type) {
      // add type attribute to element
      this.renderer.setAttribute(this.elementRef.nativeElement, 'type', this.type || this.data.type);
    }
  }

  enterEditMode() {
    this.findingForm.setValue({
      variable: this.data?.variable || '',
      value: this.data?.value || ''
    });
    this.inEditMode = true;
    setTimeout(() => {
      this.modeChange.emit(true);
    });
  }

  exitEditMode() {
    if (this.data) {
      this.inEditMode = false;
      setTimeout(() => {
        this.modeChange.emit(false);
      });
    }
  }

  save() {
    // cancel if form is invalid
    if (this.findingForm.invalid) {
      this.notify.warn('Bitte beide Felder ausfüllen!');
      return;
    }

    if (!this.data) {
      // save new finding
      this.findingService.save({
        type: this.type,
        typeId: this.typeId,
        variable: this.findingForm.value.variable,
        value: this.findingForm.value.value
      }).subscribe(result => {
        this.data = result;
        this.exitEditMode();
        this.saved.emit(this.data);
      });
    } else {
      // update existing finding
      // patch only if values have changed
      if (this.data.variable === this.findingForm.value.variable
        && this.data.value === this.findingForm.value.value) {
        this.exitEditMode();
        return;
      }

      this.data.variable = this.findingForm.value.variable;
      this.data.value = this.findingForm.value.value;

      this.data.patch()
        .subscribe(result => {
          this.data = result;
          this.exitEditMode();
          this.saved.emit(this.data);
        }, err => {
          console.error(err);
          this.notify.error('Speichern fehlgeschlagen!', JSON.stringify(err.error || err));
        });
    }
  }

  async remove() {
    if (this.data) {
      // confirm action
      const confirm = await this.confirmModal.confirm({
        message: 'Wollen Sie den Befund wirklich löschen?'
      });

      if (!confirm) {
        return;
      }

      this.data.remove()
        .subscribe(() => {
          this.data = null;
          this.removed.emit(this.data);
          this.enterEditMode();
        }, err => {
          if (err.status === 409 && err.error.message === 'DEEPLY_CONNECTED') {
            this.notify.error('Zu tief vernetzt!', 'Der Befund ist noch Teil des Regelgraphen und kann nicht gelöscht werden!');
          } else {
            console.error(err);
            this.notify.error('Löschen fehlgeschlagen!', JSON.stringify(err.error || err));
          }
        });
    } else {
      this.enterEditMode();
    }
  }

}
