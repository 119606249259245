import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard';
import { PageContainerComponent } from '@core/page-container/page-container.component';
import { HomeComponent } from '@modules/public/pages/home/home.component';
import { ImprintComponent } from '@modules/public/pages/imprint/imprint.component';
import { PrivacyComponent } from '@modules/public/pages/privacy/privacy.component';
import { AdminGuard } from '@modules/admin/guards/admin.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: PageContainerComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
      },
      {
        path: 'imprint',
        component: ImprintComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then(mod => mod.UserModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'studies',
        loadChildren: () => import('./modules/studies/studies.module').then(mod => mod.StudiesModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'studies/:studyId',
    loadChildren: () => import('./modules/study/study.module').then(mod => mod.StudyModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule),
    canLoad: [AuthGuard, AdminGuard],
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        paramsInheritanceStrategy: 'always',
        preloadingStrategy: PreloadAllModules,
        relativeLinkResolution: 'legacy'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
