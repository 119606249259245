import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Restangular, RestCollection, RestElement } from 'ngx-restangular-typed';
import { RouteParserService } from '@shared/services/route-parser.service';
import { GraphNode } from '@shared/utils/graph-node';
import { GraphLink } from '@shared/utils/graph-link';
import {
  GraphUpdates,
  LinkData,
  NodeData,
  RawGraphUpdates,
  RawLinkData,
  RestGraphLink,
  RestGraphNode
} from '@data/schema/graph';

@Injectable({
  providedIn: 'root'
})
export class GistGraphService {

  constructor(
    private rest: Restangular,
    private routeParser: RouteParserService,
  ) { }

  base(): RestElement {
    return this.rest
      .one('studies', this.routeParser.getParam('studyId'))
      .one('documents', this.routeParser.getParam('documentId'))
      .one('segments', this.routeParser.getParam('segmentId'))
      .one('graph');
  }

  restangularizeNode(element: NodeData): void {
    this.rest.restangularizeElement(this.base(), element, 'nodes');
  }

  restangularizeLink(element: LinkData): void {
    this.rest.restangularizeElement(this.base(), element, 'link');
  }

  /**
   * Query nodes and links of local graph.
   */
  query(): Observable<{nodes: RestGraphNode[], links: RestGraphLink[]}> {
    const base = this.base();
    const nodes$ = base.all<NodeData>('nodes').getList();
    const links$ = base.all<RawLinkData>('links').getList();

    return combineLatest([nodes$, links$])
      .pipe(
        map(result => {
          // init node and link classes, reference nodes in links
          const nodes: RestGraphNode[] = result[0].map(node => new GraphNode(node) as RestGraphNode);

          const links: RestGraphLink[] = result[1].map(link => {
            // construct link data
            const linkData: LinkData = Object.assign(link, {
              source: nodes.find(node => node.id === link.source),
              target: nodes.find(node => node.id === link.target)
            });
            return new GraphLink(linkData) as RestGraphLink;
          });

          return { nodes, links };
        })
      );
  }

  queryNodes(): Observable<RestCollection<NodeData>> {
    return this.base()
      .all<NodeData>('nodes')
      .getList();
  }

  queryLinks(): Observable<RestCollection<LinkData>> {
    return this.base()
      .all<LinkData>('links')
      .getList();
  }

  /**
   * Create empty finding and save as part of local graph.
   */
  saveNode(phenomenonId: string, x = 0, y = 0): Observable<RestGraphNode> {
    return this.base()
      .all('nodes')
      .post<NodeData>({
        phenomenonId, x, y
      })
      .pipe(
        map(value => new GraphNode(value) as RestGraphNode)
      );
  }

  /**
   * Save link between source and target in local graph.
   */
  saveLink(source: GraphNode, target: GraphNode): Observable<RestGraphLink> {
    return this.base()
      .all('links')
      .post<RawLinkData>({
        sourceId: source.id,
        targetId: target.id
      })
      .pipe(
        map(value => {
          const linkData: LinkData = Object.assign(value, {source, target});
          return new GraphLink(linkData) as RestGraphLink;
        })
      );
  }

  saveConditionNode(type: 'if'|'then', node: GraphNode, link: GraphLink, x = 0, y = 0): Observable<GraphUpdates> {
    return this.base()
      .all('condition')
      .post<RawGraphUpdates>({
        type, x, y, nodeId: node.id, linkId: link.id
      })
      .pipe(
        map(value => {
          // turn node data into GraphNode
          const nodesCreated = value.nodesCreated.map(nodeData => {
            this.restangularizeNode(nodeData);
            return new GraphNode(nodeData) as RestGraphNode;
          });

          // turn link data into GraphLink and set references to nodes
          const availableNodes = [node, link.source, link.target].concat(nodesCreated);
          const linksCreated = value.linksCreated.map(rawLinkData => {
            const linkData: LinkData = Object.assign(rawLinkData, {
              source: availableNodes.find(n => n.id === rawLinkData.source),
              target: availableNodes.find(n => n.id === rawLinkData.target)
            });
            this.restangularizeLink(linkData);
            return new GraphLink(linkData) as RestGraphLink;
          });

          return {
            nodesCreated,
            linksCreated,
            nodesDeleted: value.nodesDeleted,
            linksDeleted: value.linksDeleted
          };
        })
      );
  }

  addRule(x = 0, y = 0): Observable<GraphUpdates> {
    return this.base()
      // @ts-ignore
      .post<RawGraphUpdates>(null, { x, y })
      .pipe(
        map((value: RawGraphUpdates) => {
          // turn node data into GraphNode
          const nodesCreated = value.nodesCreated.map(nodeData => {
            this.restangularizeNode(nodeData);
            return new GraphNode(nodeData) as RestGraphNode;
          });

          // turn link data into GraphLink and set references to nodes
          const linksCreated = value.linksCreated.map(rawLinkData => {
            const linkData: LinkData = Object.assign(rawLinkData, {
              source: nodesCreated.find(n => n.id === rawLinkData.source),
              target: nodesCreated.find(n => n.id === rawLinkData.target)
            });
            this.restangularizeLink(linkData);
            return new GraphLink(linkData) as RestGraphLink;
          });

          return {
            nodesCreated,
            linksCreated,
            nodesDeleted: value.nodesDeleted,
            linksDeleted: value.linksDeleted
          };
        })
      );
  }

  removeRule(): Observable<GraphUpdates> {
    return this.base().remove() as Observable<GraphUpdates>;
  }

}
