import { Directive, HostListener, Input } from '@angular/core';
import { GraphMessageService } from '@shared/services/graph-message.service';
import { Finding } from '@data/schema/finding';

/**
 * This directive listens to mouse enter and leave events of finding elements and triggers the hover node event.
 */
@Directive({
  selector: 'finding[findingHover]'
})
export class FindingHoverDirective {

  @Input() data: Finding;

  constructor(
    private graphMessageService: GraphMessageService
  ) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.graphMessageService.hoverNode$.next({ nodeId: this.data.id, hover: true });
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.graphMessageService.hoverNode$.next({ nodeId: this.data.id, hover: false });
  }

}
