import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropEvent } from 'ng-drag-drop';
import { NotificationsService } from 'angular2-notifications';
import { TranslocoService } from '@ngneat/transloco';
import { Vector2 } from 'three';
import * as d3 from 'd3';
import { D3jsUtils, DragStartType } from '@shared/utils/d3js-utils';
import { GraphMessageService } from '@shared/services/graph-message.service';
import { GraphManager } from '@shared/utils/graph-manager';
import { GraphNode } from '@shared/utils/graph-node';
import { GraphLink } from '@shared/utils/graph-link';
import { GraphMergeNode } from '@shared/utils/graph-merge-node';
import { GraphContextMenuComponent, IContextMenuEntry } from '../graph-context-menu/graph-context-menu.component';
import { GraphSourcePopoverComponent } from '../graph-source-popover/graph-source-popover.component';
import { GraphService } from '@data/service/graph.service';
import { GistGraphService } from '@data/service/gist-graph.service';
import { RestGraphLink, RestGraphNode, GraphUpdates } from '@data/schema/graph';

// constants
const findingWidth = 260;
const findingHeight = 60;
const variableWidth = 200;
const variableHeight = 60;
const valueWidth = 60;
const valueHeight = 60;
const gridResolution = 20;

const valueOffset = (index: number, length: number) => (variableWidth - length * valueWidth + 2 * index * valueWidth) / 2;


@Component({
  selector: 'graph-editor',
  templateUrl: './graph-editor.component.html',
  styleUrls: ['./graph-editor.component.sass']
})
export class GraphEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  private WIDTH: number;
  private HEIGHT: number;

  private manager = new GraphManager<RestGraphNode, RestGraphLink>();
  private svg: d3.Selection<SVGElement, unknown, null, undefined>;
  private container: d3.Selection<SVGGElement, unknown, null, undefined>;
  private zoomHandle: d3.Selection<SVGRectElement, unknown, null, undefined>;
  private zoomBehavior: d3.ZoomBehavior<SVGRectElement, unknown>;
  private dragBehavior: d3.DragBehavior<SVGGElement, GraphMergeNode, any>;
  private dragLine: d3.Selection<SVGPathElement, unknown, null, undefined>;
  private hoverLinkGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
  private ajaxSpinner: d3.Selection<SVGGElement, unknown, null, undefined>;
  private blindFinding: d3.Selection<SVGGElement, unknown, null, undefined>;
  private linkCurveGen: (d: GraphLink) => string;
  // private containerBBox = {x: 0, y: 0, width: 0, height: 0};
  private dragStartPosition: Vector2;
  private dragStartNode: GraphNode;
  private dragStartType: DragStartType;
  private selected: d3.Selection<SVGGElement, RestGraphNode|RestGraphLink, null, undefined>;
  private highlighted: d3.Selection<SVGGElement, RestGraphNode|RestGraphLink, any, undefined>[] = [];

  graphStatus = 1;

  @Input() editable = false;
  @Input() filterByType: string;
  @Input() mode: string[] = [];
  // @Input() mode: ('global'|'gist'|'target'|'latent')[] = [];

  @ViewChild('svgElement', { static: true }) svgElement: ElementRef;
  @ViewChild('contextmenu', { static: true }) contextmenu: GraphContextMenuComponent;
  @ViewChild('sourcePopover', { static: true }) sourcePopover: GraphSourcePopoverComponent;

  constructor(
    private graphService: GraphService,
    private graphMessageService: GraphMessageService,
    private gistGraphService: GistGraphService,
    private notify: NotificationsService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {
  }

  async ngAfterViewInit() {
    // query nodes and links
    const {nodes, links} = await this.graphService
      .query(this.filterByType ? {type: this.filterByType} : null)
      .toPromise();

    nodes.forEach(node => {
      this.graphMessageService.nodesInUse$.next({node, inUse: true});
    });

    this.manager.addLink(...links);
    this.manager.addNode(...nodes);
    // console.log(this.manager);

    this.graphStatus = this.manager.nodes.length ? 0 : 2;

    // look for same variable values and merge

    this.initSvg();

    this.draw();

    this.zoomToFit();

    // highlight node
    this.graphMessageService.hoverNode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        const { node } = this.manager.findNode(event.nodeId);
        if (event.hover && node) {
          this.highlight(node);
        } else {
          this.highlight();
        }
      });

    this.graphMessageService.update$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(updates => {
        this.updateData(updates);
      });
  }

  private initSvg() {
    this.svg = d3.select(this.svgElement.nativeElement);

    const rect = this.svgElement.nativeElement.getBoundingClientRect();
    this.WIDTH = rect.width;
    this.HEIGHT = rect.height;

    // pan/zoom behavior
    this.zoomBehavior = d3.zoom<SVGRectElement, unknown>()
      .scaleExtent([0.5, 1])
      .on('zoom', event => {
        this.container.attr('transform', event.transform);
      });

    this.zoomHandle = this.svg.select<SVGRectElement>('rect.drag-handle')
      .attr('width', this.WIDTH)
      .attr('height', this.HEIGHT)
      .on('click', () => { this.select(); this.highlight(); })
      .on('mousedown', () => { this.hideContextMenu(); }) // d3.zoom stops propagation
      .call(this.zoomBehavior);

    this.container = this.svg.select('g');

    this.dragLine = this.container.append('path')
      .attr('class', 'link drag-line hidden')
      .attr('d', 'M0,0L0,0');

    this.hoverLinkGroup = this.container.append('g')
      .attr('class', 'hover-link');

    this.ajaxSpinner = this.container.append('g')
      .attr('class', 'ajax-spinner hidden');
    this.ajaxSpinner.append('foreignObject')
      .attr('x', -13)
      .attr('y', -13)
      .append('xhtml:img')
      .attr('src', 'assets/loading.svg');

    this.blindFinding = this.container.append('g')
      .attr('class', 'blind Finding hidden');
      // .attr('hidden', true);
    this.blindFinding.append('rect')
      .attr('class', 'finding-variable')
      .attr('x', -findingWidth / 2)
      .attr('y', -findingHeight / 2);
    this.blindFinding.append('rect')
      .attr('class', 'finding-value')
      .attr('x', findingWidth / 2 - valueWidth)
      .attr('y', -findingHeight / 2);

    // initial position in center of viewport
    this.zoomHandle.call(this.zoomBehavior.transform, d3.zoomIdentity.translate(this.WIDTH / 2, this.HEIGHT / 2).scale(1));

    // link generator
    this.linkCurveGen = (d: GraphLink) => {
      // start and end points
      let x0: number;
      let y0: number;
      let x1: number;
      let y1: number;

      if (d.source.type.includes('Finding')) {
        const source = this.manager.findNode(d.source);
        x0 = source.parent.x + valueOffset(source.index, source.parent.nodes.length) + valueWidth / 2;
        y0 = source.parent.y + findingHeight / 2;
      } else {
        x0 = d.source.x;
        y0 = d.source.y + findingHeight / 2;
      }

      if (d.target.type.includes('Finding')) {
        const target = this.manager.findNode(d.target);
        x1 = target.parent.x + valueOffset(target.index, target.parent.nodes.length) + valueWidth / 2;
        y1 = target.parent.y - findingHeight / 2;
      } else {
        x1 = d.target.x;
        y1 = d.target.y - findingHeight / 2;
      }

      // control points
      let c0 = (y0 + y1) / 2;
      let c1 = c0;

      if ((y0 + 20) > (y1 - 20)) {
        const diff = Math.max(100, Math.abs(y1 - y0) * 0.25);
        c0 = y0 + diff;
        c1 = y1 - diff;
      }

      // generate bezier curve
      const path = d3.path();
      path.moveTo(x0, y0);
      path.bezierCurveTo(x0, c0, x1, c1, x1, y1);

      return path.toString();
    };

    // background events
    this.svg
      .on('mousedown', () => { this.hideContextMenu(); })
      .on('mousemove', (event) => { this.svgMouseMove(event); })
      .on('mouseup', () => { this.svgMouseUp(); });

    // node drag behavior
    this.dragBehavior = d3.drag<SVGGElement, GraphMergeNode>()
      .on('start', (event, d) => {
        this.dragStartPosition = new Vector2(d.x, d.y);

        // hide link blind node and arrows
        const link = d3.selectAll('g.link').filter((ld: GraphLink) => d.nodes.includes(ld.source) || d.nodes.includes(ld.target));
        link.select('circle').attr('hidden', true);
        link.selectAll('path.arrow-head').attr('hidden', true);
      })
      .on('drag', (event, d) => {
        // reposition node
        d.set(event.x, event.y);
        d.nodes.forEach(n => {
          n.x = d.x;
          n.y = d.y;
        });
        d3.select('#' + d.id).attr('transform', `translate(${d.x},${d.y})`);

        // redraw associated link paths
        const link = d3.selectAll('g.link').filter((ld: GraphLink) => d.nodes.includes(ld.source) || d.nodes.includes(ld.target));
        link.select('path')
          .attr('d', this.linkCurveGen);
      })
      .on('end', (event, d) => {
        // update if node has been repositioned
        if (!this.dragStartPosition.equals(d)) {
          d.nodes.forEach((n: RestGraphNode) => {
            n.patch({x: d.x, y: d.y});
          });
        }
        this.dragStartPosition = null;

        // reposition and toggle link blind node and arrows
        const link = d3.selectAll<SVGGElement, GraphLink>('g.link')
          .filter((ld: GraphLink) => d.nodes.includes(ld.source) || d.nodes.includes(ld.target));
        link.each((datum, i, g) => {
          const group = d3.select(g[i]);
          const path = group.select<SVGPathElement>('path:not(.arrow-head').node();
          const linkArrowPositions = this.editable && datum.type.some(t => this.mode.includes(t)) ? [0.25, 0.75] : [0.5];
          const posC = D3jsUtils.computePositionOnPath(path);
          group.select('circle')
            .attr('cx', posC.x)
            .attr('cy', posC.y)
            .attr('hidden', null);
          group.selectAll('path.arrow-head')
            .attr('hidden', null)
            .attr('transform', (dp, index) => {
              const posA = D3jsUtils.computePositionOnPath(path, linkArrowPositions[index]);
              return `translate(${posA.x},${posA.y})rotate(${posA.angle})`;
            });
        });
      });

  }

  private draw() {
    // links
    let linkAll = this.container.selectAll<SVGGElement, GraphLink>('g.link')
      .data(this.manager.links, d => d.id);

    // link remove
    linkAll.exit().remove();

    const linkEnter = linkAll.enter()
      .insert('g', ':first-child')
      .attr('id', d => d.id)
      .attr('class', d => 'link ' + d.type.join(' '))
      .on('contextmenu', this.showContextMenu.bind(this))
      .on('mouseenter', (event, d) => { this.hoverLink(d); })
      .on('mouseleave', () => { this.hoverLink(); });
    linkEnter.append('path');
    linkEnter.append('path')
      .attr('class', 'arrow-head')
      .attr('d', 'M-5,-5L5,0L-5,5');

    if (this.editable) {
      const linkEditable = linkEnter.filter(d => d.type.some(t => this.mode.includes(t)));

      // add another arrow
      linkEditable.append('path')
        .attr('class', 'arrow-head')
        .attr('d', 'M-5,-5L5,0L-5,5');

      // add blind node
      linkEditable.append('circle')
        .attr('r', 7)
        .on('mouseup', (event, d) => {
          this.blindMouseUp(event.currentTarget, d);
        });

      linkEditable.on('click', (event, d) => this.select(event.currentTarget, d));
    }

    linkAll = linkEnter.merge(linkAll);

    // update transforms
    linkAll.select('path:not(.arrow-head)')
      .attr('d', this.linkCurveGen);

    linkAll.each((d, index, group) => {
      const link = d3.select(group[index]);
      const path = link.select<SVGPathElement>('path:not(.arrow-head)').node();
      const linkArrowPositions = this.editable && d.type.some(t => this.mode.includes(t)) ? [0.25, 0.75] : [0.5];

      const posCircle = D3jsUtils.computePositionOnPath(path);
      link.select('circle')
        .attr('cx', posCircle.x)
        .attr('cy', posCircle.y);

      link.selectAll('path.arrow-head')
        .attr('transform', (datum, i) => {
          const posArrow = D3jsUtils.computePositionOnPath(path, linkArrowPositions[i]);
          return `translate(${posArrow.x},${posArrow.y})rotate(${posArrow.angle})`;
        });
    });

    // nodes
    let nodeAll = this.container.selectAll<SVGGElement, GraphMergeNode>('g.node')
      .data(this.manager.nodes, d => d.id);

    // remove nodes that are not part of data anymore
    nodeAll.exit().remove();

    // enter new nodes
    const nodeEnter = nodeAll.enter()
      .insert('g', 'path.drag-line')
      .attr('id', d => d.id)
      .attr('class', d => 'node ' + d.type.join(' '));

    // finding nodes
    const findingEnter = nodeEnter.filter(d => d.type.includes('Finding'));
    const fVariable = findingEnter.append('g')
      .attr('class', 'finding-variable');
    fVariable.append('rect');
    fVariable.append('foreignObject')
      .append('xhtml:div')
      .append<HTMLDivElement>('xhtml:div')
      .text(d => d.nodes[0].data.variable)
      .each((d, index, group) => {
        D3jsUtils.fitText(group[index]);
      });

    // condition nodes
    const condition = nodeEnter.filter(d => d.type.includes('If') || d.type.includes('Then'));
    const conditionGroup = condition.append('g').attr('id', d => d.nodes[0].id);
    conditionGroup.append('path')
      .attr('d', `M0,${-findingHeight / 2} L${-findingHeight / 2},0 L0,${findingHeight / 2} L${findingHeight / 2},0 Z`);
    conditionGroup.append('text')
      .text(d => {
        if (d.type.includes('And')) { return this.translocoService.translate('EDITOR.and'); }
        if (d.type.includes('Or')) { return this.translocoService.translate('EDITOR.or'); }
        return '?';
      });
    condition.on('contextmenu', (event, d) => this.showContextMenu(event, d.nodes[0]));

    if (this.editable) {
      const conditionEditable = condition.filter(d => this.mode.includes(d.nodes[0].data.type));

      conditionEditable
        .on('click', (event, d) => this.select(event.currentTarget, d.nodes[0]));

      // link interfaces
      conditionEditable.append('circle')
        .attr('class', 'incoming')
        .attr('cy', -findingHeight / 2)
        .attr('r', 7)
        .on('mousedown', (event, d) => this.nodeMouseDown(event, event.currentTarget, d.nodes[0], DragStartType.Incoming ))
        .on('mouseup', (event, d) => this.nodeMouseUp(event.currentTarget, d.nodes[0], DragStartType.Incoming ))
        .on('mouseover', (event, d) => this.nodeMouseOver(event.currentTarget, d.nodes[0], DragStartType.Incoming))
        .on('mouseout', (event, d) => this.nodeMouseOut(event.currentTarget, d.nodes[0]));
      conditionEditable.append('circle')
        .attr('class', 'outgoing')
        .attr('cy', findingHeight / 2)
        .attr('r', 7)
        .on('mousedown', (event, d) => this.nodeMouseDown(event, event.currentTarget, d.nodes[0], DragStartType.Outgoing))
        .on('mouseup', (event, d) => this.nodeMouseUp(event.currentTarget, d.nodes[0], DragStartType.Outgoing))
        .on('mouseover', (event, d) => this.nodeMouseOver(event.currentTarget, d.nodes[0], DragStartType.Outgoing))
        .on('mouseout', (event, d) => this.nodeMouseOut(event.currentTarget, d.nodes[0]));
    }

    nodeEnter.on('mousedown', () => { this.hideContextMenu(); this.highlight(); });

    if (this.editable) {
      nodeEnter.call(this.dragBehavior);
    }

    // merge entered nodes with pre-existing
    nodeAll = nodeEnter.merge(nodeAll);

    nodeAll.attr('transform', d => `translate(${d.x},${d.y})`);

    const finding = nodeAll.filter(d => d.type.includes('Finding'));

    finding.select('g.finding-variable')
      .attr('transform', d => `translate(${-(variableWidth + d.nodes.length * valueWidth) / 2},${-findingHeight / 2})`);

    // select sub nodes
    let fValue = finding.selectAll<SVGGElement, RestGraphNode>('g.finding-value')
      .data(d => d.nodes, d => d.id);

    fValue.exit().remove();

    const fValueEnter = fValue.enter()
      .append('g')
      .attr('id', d => d.id)
      .attr('class', d => 'finding-value ' + d.data.type)
      .on('contextmenu', this.showContextMenu.bind(this));
      // .attr('transform', (d, i) => `translate(${valueOffset(i, d.parent.nodes.length)},${-findingHeight / 2})`)
      // .on('click', (event, d) => this.select(event.currentTarget, d));
    fValueEnter.append('rect');
    fValueEnter.append('text')
      .attr('x', valueWidth / 2)
      .attr('y', valueHeight / 2)
      .text(d => this.translocoService.translate(d.data.value))
      .each((d, i, g) => {
        D3jsUtils.fitText(g[i], {maxWidth: valueWidth - 4, maxHeight: valueHeight});
      });
    fValueEnter.each((d, i, g) => {
      if (i > 0) {
        d3.select(g[i]).append('line')
          .attr('y1', 1)
          .attr('y2', valueHeight - 1);
      }
    });

    if (this.editable) {
      // link interfaces
      fValueEnter.append('circle')
        .attr('class', 'incoming')
        .attr('cx', valueWidth / 2)
        .attr('r', 7)
        .on('mousedown', (event, d) => this.nodeMouseDown(event, event.currentTarget, d, DragStartType.Incoming))
        .on('mouseup', (event, d) => this.nodeMouseUp(event.currentTarget, d, DragStartType.Incoming))
        .on('mouseover', (event, d) => this.nodeMouseOver(event.currentTarget, d, DragStartType.Incoming))
        .on('mouseout', (event, d) => this.nodeMouseOut(event.currentTarget, d));
      fValueEnter.append('circle')
        .attr('class', 'outgoing')
        .attr('cx', valueWidth / 2)
        .attr('cy', findingHeight)
        .attr('r', 7)
        .on('mousedown', (event, d) => this.nodeMouseDown(event, event.currentTarget, d, DragStartType.Outgoing))
        .on('mouseup', (event, d) => this.nodeMouseUp(event.currentTarget, d, DragStartType.Outgoing))
        .on('mouseover', (event, d) => this.nodeMouseOver(event.currentTarget, d, DragStartType.Outgoing))
        .on('mouseout', (event, d) => this.nodeMouseOut(event.currentTarget, d));

      fValueEnter.filter(d => this.mode.includes(d.data.type))
        .on('click', (event, d) => this.select(event.currentTarget, d));
    }

    // update transform of all finding values
    fValue = fValueEnter.merge(fValue);
    fValue.attr('transform', (d, i) => `translate(${valueOffset(i, d.parent.nodes.length)},${-findingHeight / 2})`);

  }

  /**
   * Remove old nodes/links and add new nodes/links to data.
   */
  private updateData(updates: GraphUpdates) {
    updates.nodesDeleted.forEach(nodeId => {
      const removed = this.manager.removeNode(nodeId);
      if (removed) {
        this.graphMessageService.nodesInUse$.next({node: removed, inUse: false});
      }
    });

    updates.linksDeleted.forEach(linkId => {
      this.manager.removeLink(linkId);
    });

    this.manager.addNode(...updates.nodesCreated);
    this.manager.addLink(...updates.linksCreated);
    updates.nodesCreated.forEach(node => {
      this.graphMessageService.nodesInUse$.next({ node, inUse: true });
    });

    this.graphStatus = this.manager.nodes.length ? 0 : 2;

    this.draw();
  }

  private nodeMouseOver(element, d, type) {
    if (!this.dragStartNode || d === this.dragStartNode || type === this.dragStartType) { return; }
    d3.select(element).classed('link-hover', true);
  }

  private nodeMouseOut(element, d) {
    if (!this.dragStartNode || d === this.dragStartNode) { return; }
    d3.select(element).classed('link-hover', false);
  }

  private nodeMouseDown(event, element, d: GraphNode, type: DragStartType) {
    event.stopPropagation();

    // check for multiple outgoing/incoming links on condition nodes
    if (d.type.includes('If') && type === DragStartType.Outgoing
      || d.type.includes('Then') && type === DragStartType.Incoming) {
      return;
    }

    this.dragStartNode = d;
    this.dragStartType = type;

    const merged = this.manager.findNode(d);
    const x = merged.parent.x +
      (merged.parent.type.includes('Finding') ? valueOffset(merged.index, merged.parent.nodes.length) + valueWidth / 2 : 0);

    // reposition drag line
    this.dragLine.classed('hidden', false)
      .attr('d',
        `M${x},${merged.parent.y + type * findingHeight / 2}` +
        `L${x},${merged.parent.y + type * findingHeight / 2}`);
    this.svg.classed('line-dragging', true);
  }

  /**
   * Add new link when user releases drawing link on node's link interfaces.
   */
  private nodeMouseUp(element: SVGCircleElement, d: GraphNode, type: DragStartType) {
    if (!this.dragStartNode) { return; }

    d3.select(element).classed('link-hover', false);

    const dragEndNode = d;

    // check for drag-to-self and incoming/outgoing consistency
    if (this.dragStartNode === dragEndNode || this.dragStartType === type) { return; }

    // check if link already exists
    if (this.manager.findLink(this.dragStartNode, dragEndNode)) {
      return;
    }

    // check if connection has been drawn between non-editable nodes of same origin
    if (!this.mode.includes('target') && this.dragStartNode.data.type === 'target' && dragEndNode.data.type === 'target') {
      this.notify.warn('Verknüpfung zwischen Zielvariablen nicht möglich!', 'Zielkonstrukt an dieser Stelle nicht bearbeitbar.');
      return;
    }
    if (!this.mode.includes('gist') && this.dragStartNode.data.gist && dragEndNode.data.gist &&
      this.dragStartNode.data.gist === dragEndNode.data.gist) {
      this.notify.warn('Verknüpfung zwischen Knoten derselben Kernaussage nicht möglich!', 'Regel einer Kernaussage an dieser Stelle nicht bearbeitbar.');
      return;
    }
    if (!this.mode.includes('latent') && !(this.dragStartNode.data.type === 'target' &&
      dragEndNode.data.type === 'target' || this.dragStartNode.data.gist && dragEndNode.data.gist &&
      this.dragStartNode.data.gist === dragEndNode.data.gist)) {
      this.notify.warn('Latente Verknüpfung nicht möglich!', 'Latente Regelelemente an dieser Stelle nicht bearbeitbar.');
      return;
    }

    // check if connection is allowed on existing condition nodes
    // (only one outgoing connection for if nodes, only one incoming connection for then nodes)
    if (dragEndNode.type.includes('If') && type === DragStartType.Outgoing ||
      dragEndNode.type.includes('Then') && type === DragStartType.Incoming ||
      this.dragStartNode.type.includes('If') && this.dragStartType === DragStartType.Outgoing ||
      this.dragStartNode.type.includes('Then') && this.dragStartType === DragStartType.Incoming) {
      this.notify.warn('Verknüpfung nicht möglich!', 'WENN-Knoten können nur einen Ausgang, DANN-Knoten nur einen Eingang haben.');
      return;
    }

    // save new link
    const source = type === DragStartType.Outgoing ? dragEndNode : this.dragStartNode;
    const target = type === DragStartType.Outgoing ? this.dragStartNode : dragEndNode;

    this.setAjaxSpinner(source, target);

    this.graphService.saveLink(source, target).subscribe(result => {
      console.log(result);
      this.setAjaxSpinner();

      this.manager.addLink(result);
      this.draw();
    });
  }

  /**
   * Add new condition node and links when user releases drawing link on blind node.
   */
  private blindMouseUp(element: SVGCircleElement, d: RestGraphLink) {
    if (!this.dragStartNode) { return; }

    let conditionType;

    // check if connection is allowed

    // avoid drag on neighboured link
    if (this.dragStartNode === d.source || this.dragStartNode === d.target) {
      return;
    }

    if (this.dragStartType === DragStartType.Outgoing) {
      if (this.dragStartNode.type.includes('Finding')) {
        if (d.source.type.includes('Finding') || d.source.type.includes('If')) {
          conditionType = 'if';
        }
      } else if (this.dragStartNode.type.includes('Then')) {
        if (d.target.type.includes('Finding') || d.target.type.includes('Then')) {
          conditionType = 'then';
        }
      }
    } else {
      if (this.dragStartNode.type.includes('Finding')) {
        if (d.target.type.includes('Finding') || d.target.type.includes('Then')) {
          conditionType = 'then';
        }
      } else if (this.dragStartNode.type.includes('If')) {
        if (d.source.type.includes('Finding') || d.source.type.includes('If')) {
          conditionType = 'if';
        }
      }
    }

    if (!conditionType) {
      return;
    }

    // get element position
    const circle = d3.select(element);
    const x = parseFloat(circle.attr('cx'));
    const y = parseFloat(circle.attr('cy'));

    this.setAjaxSpinner(this.dragStartNode.x, this.dragStartNode.y, x, y);

    // save new condition node
    this.graphService.saveConditionNode(conditionType, this.dragStartNode, d, x, y)
      .subscribe(response => {
        console.log(response);
        this.setAjaxSpinner();
        this.updateData(response);
      });
  }

  // svg mousemove event handler
  private svgMouseMove(event) {
    if (!this.dragStartNode) { return; }

    const merged = this.manager.findNode(this.dragStartNode);
    const x = merged.parent.x + valueOffset(merged.index, merged.parent.nodes.length) + valueWidth / 2;

    // update drag line
    const cMouse = d3.pointer(event, this.container.node());
    this.dragLine.attr('d',
      `M${x},${merged.parent.y + this.dragStartType * findingHeight / 2}L${cMouse[0]},${cMouse[1]}`);
  }

  // svg mouseup event handler
  private svgMouseUp() {
    if (this.dragStartNode) {
      // hide drag line
      this.dragLine.classed('hidden', true);
      this.svg.classed('line-dragging', false);

      this.dragStartNode = null;
      this.dragStartType = null;
    }
  }

  /**
   * Handler when droppable finding enters the area.
   */
  onDragEnter() {
    this.blindFinding.classed('hidden', false);
  }

  /**
   * Handler when droppable finding is dragged over the area.
   */
  onDragOver(event: DragEvent) {
    const t = d3.zoomTransform(this.zoomHandle.node());
    const x = (event.offsetX - t.x) / t.k;
    const y = (event.offsetY - t.y) / t.k;

    this.blindFinding
      .attr('transform', `translate(${x},${y})`);
  }

  /**
   * Handler when droppable finding leaves the area.
   */
  onDragLeave() {
    this.blindFinding.classed('hidden', true);
  }

  /**
   * Handler for dropped findings from outside.
   */
  onDragDrop(event: DropEvent) {
    console.log('drop', event);
    const t = d3.zoomTransform(this.zoomHandle.node());
    const x = (event.nativeEvent.offsetX - t.x) / t.k;
    const y = (event.nativeEvent.offsetY - t.y) / t.k;

    this.blindFinding.classed('hidden', true);
    this.setAjaxSpinner(x, y);

    if (event.dragData.type === 'gist') {
      // add local rule to graph
      this.gistGraphService.addRule(x, y).subscribe(response => {
        this.setAjaxSpinner();
        this.updateData(response);
      });
    } else {
      // save node
      this.graphService.saveNode(event.dragData.id, x, y).subscribe(result => {
        event.dragData.inUse = true;

        this.manager.addNode(result);
        this.graphMessageService.nodesInUse$.next({node: result, inUse: true});

        if (this.manager.nodes.length) {
          this.graphStatus = 0;
        }

        this.setAjaxSpinner();
        this.draw();
      });
    }
  }

  /**
   * Selects the datum by highlighting corresponding element.
   * @param element - Element to be selected
   * @param d - Datum to be selected
   */
  private select(element?, d?: GraphNode|GraphLink) {
    console.log('click', element, d);
    if (this.selected) {
      this.selected.classed('selected', false);
      this.selected = null;
    }

    if (element && d) {
      this.selected = d3.select(element);
      this.selected.classed('selected', true);
    }
  }

  /**
   * Hover link by rendering paths in front of all nodes.
   * @param d - GraphLink data. If omitted, just clear hover group.
   * @private
   */
  private hoverLink(d?: GraphLink): void {

    this.hoverLinkGroup.html(null);

    if (d) {
      const link = this.svg.select('g#' + d.id);
      this.hoverLinkGroup.html(link.html());
      this.hoverLinkGroup.classed('temporal', link.classed('temporal'));
    }

  }

  /**
   * Highlight one or more node and links. If nothing is provided, just remove existing highlights.
   * @private
   */
  private highlight(d?: GraphNode|GraphLink|(GraphNode|GraphLink)[]): void;
  /**
   * Highlight a subtree starting from a node or link.
   * @private
   */
  private highlight(d: GraphNode|GraphLink, traverse: true): void;
  private highlight(d?: GraphNode|GraphLink|(GraphNode|GraphLink)[], traverse = false): void {

    this.highlighted.forEach(item => {
      item.attr('filter', null);
    });
    this.highlighted.length = 0;

    if (!d) { return; }

    d = Array.isArray(d) ? d : [d];

    if (traverse) {
      // find subtree of first element
      const startNode = d[0] instanceof GraphNode ? d[0] : d[0].source;
      d = this.manager.findSubtree(startNode, node => node.data.gist === startNode.data.gist, link => link.type.includes('gist'));
    }

    d.forEach(datum => {
      const element = this.svg.select('g#' + datum.id) as d3.Selection<SVGGElement, RestGraphNode|RestGraphLink, any, undefined>;
      element.attr('filter', 'url(#errorBlur)');
      this.highlighted.push(element);
    });

  }

  /**
   * KeyUp event listener.
   */
  @HostListener('document:keyup', ['$event'])
  private onKeyUp(event: KeyboardEvent) {
    if ((event.target as HTMLElement).tagName === 'INPUT') { return; }

    switch (event.key) {
      case 'Backspace':
      case 'Delete':
        // remove selected item
        if (this.editable && this.selected) {
          this.remove(this.selected.datum());
        }
        break;
      case 'z':
        this.zoomToFit();
    }
  }

  /**
   * Remove node or link from rule graph.
   */
  private remove(item: RestGraphNode|RestGraphLink) {

    // skip if not allowed to modify item
    if (!this.editable || !this.mode.some(mode => item instanceof GraphNode ? item.data.type === mode : item.type.includes(mode))) {
      return;
    }

    this.setAjaxSpinner(item);

    item.remove().subscribe(response => {
      console.log(response);
      // turn new nodes/links into GraphNode/GraphLink
      response.nodesCreated = response.nodesCreated.map(nodeData => {
        this.graphService.restangularizeNode(nodeData);
        return new GraphNode(nodeData);
      });
      response.linksCreated = response.linksCreated.map(linkData => {
        this.graphService.restangularizeLink(linkData);
        linkData.source = this.manager.findNode(linkData.source).node;
        linkData.target = this.manager.findNode(linkData.target).node;
        return new GraphLink(linkData);
      });

      this.updateData(response);
      this.setAjaxSpinner();
      this.selected = null;
    }, err => {
      console.error(err);
      this.notify.error('Fehler beim Löschen der Nodes/Links!');
      this.setAjaxSpinner();
    });

  }

  private setAjaxSpinner(d?: GraphNode|GraphLink);
  private setAjaxSpinner(source: GraphNode, target: GraphNode);
  private setAjaxSpinner(x: number, y: number);
  private setAjaxSpinner(x1: number, y1: number, x2: number, y2: number);

  private setAjaxSpinner(dOrSource?: GraphNode|GraphLink|number, target?: GraphNode|number, x2?: number, y2?: number) {
    if (!dOrSource) {
      // hide spinner
      this.ajaxSpinner.classed('hidden', true);
      return;
    }

    if (dOrSource && !target) {
      const d = dOrSource;

      if (d instanceof GraphNode) {

        // 1 parameter as node
        this.ajaxSpinner.attr('transform', `translate(${d.x},${d.y})`);

      } else if (d instanceof GraphLink) {

        // 1 parameter as link
        this.ajaxSpinner.attr('transform', `translate(${(d.source.x + d.target.x) / 2},${(d.source.y + d.target.y) / 2})`);

      }

    } else if (dOrSource && target && !x2 && !y2 && dOrSource instanceof GraphNode && target instanceof GraphNode) {

      // 2 parameters as nodes
      const source = dOrSource;
      this.ajaxSpinner.attr('transform', `translate(${(source.x + target.x) / 2},${(source.y + target.y) / 2})`);

    } else if (dOrSource && target && !x2 && !y2 && typeof dOrSource === 'number' && typeof target === 'number') {

      // 2 parameters as numbers
      this.ajaxSpinner.attr('transform', `translate(${dOrSource},${target})`);

    } else if (dOrSource && target && x2 && y2 && typeof dOrSource === 'number' && typeof target === 'number') {

      // 4 parameters as numbers
      this.ajaxSpinner.attr('transform', `translate(${(dOrSource + x2) / 2},${(target + y2) / 2})`);

    }

    this.ajaxSpinner.classed('hidden', false);
  }

  /**
   * Show context menu with entries with respect to type and state of the datum.
   */
  private showContextMenu(event: MouseEvent | PointerEvent, d: RestGraphNode | RestGraphLink): void {

    event.preventDefault();

    this.select(event.currentTarget, d);

    const editable = this.editable && this.mode.some(mode => d instanceof GraphNode ? d.data.type === mode : d.type.includes(mode));

    let title = '';
    const entries: IContextMenuEntry[] = [];

    entries.push({
      label: 'Show source',
      callback: () => { this.sourcePopover.show(d, event); },
      icon: 'source'
    });

    // offer highlight only if datum is derived from gist
    if (d instanceof GraphNode ? d.data.gist : d.type.includes('gist')) {
      entries.push({
        label: 'EDITOR.context.highlightRule',
        callback: this.highlight.bind(this, d, true),
        icon: ['far', 'lightbulb-on']
      });
    }

    if (d instanceof  GraphNode) {
      if (d.type.includes('Finding')) {
        title = 'EDITOR.context.nodeFinding';
      } else if (d.type.includes('And')) {
        title = 'EDITOR.context.nodeAnd';
        if (editable) {
          entries.push({
            label: 'EDITOR.context.changeToOr',
            callback: this.setConditionType.bind(this, d, 'Or'),
            icon: 'exchange-alt'
          });
        }
      }
      else if (d.type.includes('Or')) {
        title = 'EDITOR.context.nodeOr';
        if (editable) {
          entries.push({
            label: 'EDITOR.context.changeToAnd',
            callback: this.setConditionType.bind(this, d, 'And'),
            icon: 'exchange-alt'
          });
        }
      }
    } else {
      title = 'EDITOR.context.link';
      if (editable) {
        if (d.type.includes('causal')) {
          entries.push({
            label: 'EDITOR.context.changeToTemporal',
            callback: this.setLinkType.bind(this, d, 'temporal'),
            icon: 'arrow-right-dashed'
          });
        } else {
          entries.push({
            label: 'EDITOR.context.changeToCausal',
            callback: this.setLinkType.bind(this, d, 'causal'),
            icon: 'arrow-right-solid'
          });
        }
      }

    }

    if (editable) {
      entries.push({ label: 'remove', callback: this.remove.bind(this, d), icon: 'times' });
    }

    if (entries.length) {
      this.contextmenu.show(title, entries, event);
    }

  }

  /**
   * Hide context menu.
   */
  private hideContextMenu(): void {

    this.contextmenu.hide();
    this.sourcePopover.hide();

  }

  /**
   * Set and save type of condition node.
   */
  private setConditionType(node: RestGraphNode, type: 'And'|'Or'): void {

    const oldType = node.type.find(value => value === 'And' || value === 'Or');
    if (oldType === type) { return; }

    const { parent: mergeNode } = this.manager.findNode(node);
    node.type[node.type.indexOf(oldType)] = type;
    mergeNode.type[mergeNode.type.indexOf(oldType)] = type;

    this.setAjaxSpinner(node);

    node.patch({ type: node.type })
      .subscribe(() => {
        // change value in DOM
        d3.select('g#' + mergeNode.id).select('text')
          .text(type === 'And' ? this.translocoService.translate('EDITOR.and') : this.translocoService.translate('EDITOR.or'));
        this.setAjaxSpinner();
      }, err => {
        console.error(err);
        this.notify.error('Fehler beim Speichern!');
        this.setAjaxSpinner();
      });
  }

  /**
   * Set and save type of link.
   */
  private setLinkType(link: RestGraphLink, type: 'causal'|'temporal'): void {

    const oldType = link.type.find(value => value === 'causal' || value === 'temporal');
    if (oldType === type) { return; }

    link.type[link.type.indexOf(oldType)] = type;

    this.setAjaxSpinner(link);

    link.patch({ type: link.type })
      .subscribe(() => {
        // update DOM
        d3.select('g#' + link.id)
          .classed(oldType, false)
          .classed(type, true);
        this.setAjaxSpinner();
      }, err => {
        console.log(err);
        this.notify.error('Fehler beim Speichern!');
        this.setAjaxSpinner();
      });

  }

  private getExtents() {
    const svgBBox = this.svg.node().getBoundingClientRect();

    let left = svgBBox.right;
    let right = svgBBox.left;
    let top = svgBBox.bottom;
    let bottom = svgBBox.top;

    this.container.selectAll<SVGGElement, GraphMergeNode>('.node')
      .each((d, index, group) => {
        const bbox = group[index].getBoundingClientRect();
        left = Math.min(left, bbox.left);
        right = Math.max(right, bbox.right);
        top = Math.min(top, bbox.top);
        bottom = Math.max(bottom, bbox.bottom);
      });

    if (left > right || top > bottom) {
      // no nodes inside, nothing to zoom/fit
      return;
    }

    const transform = d3.zoomTransform(this.zoomHandle.node());

    return {
      left: (left - svgBBox.left - transform.x) / transform.k,
      right: (right - svgBBox.left - transform.x) / transform.k,
      top: (top - svgBBox.top - transform.y) / transform.k,
      bottom: (bottom - svgBBox.top - transform.y) / transform.k,
      currentTransform: transform
    };
  }

  zoomToFit() {
    const svgBBox = this.svg.node().getBoundingClientRect();
    const extents = this.getExtents();

    // empty graph
    if (!extents) { return; }

    const {left, right, top, bottom} = extents;

    const width = right - left;
    const height = bottom - top;
    const midX = (left + right) / 2;
    const midY = (top + bottom) / 2;

    const scale = Math.min(1, .9 / Math.max(width / svgBBox.width, height / svgBBox.height));
    const tx = svgBBox.width / 2 - midX * scale;
    const ty = svgBBox.height / 2 - midY * scale;

    this.zoomHandle.transition().duration(500).call(this.zoomBehavior.transform, d3.zoomIdentity.translate(tx, ty).scale(scale));
  }

  zoomOriginalSize() {
    const extents = this.getExtents();

    // empty graph
    if (!extents) { return; }

    const {currentTransform} = extents;
    this.zoomHandle.transition().duration(500)
      .call(this.zoomBehavior.transform, d3.zoomIdentity.translate(currentTransform.x, currentTransform.y));
  }

  /**
   * Render svg tree as png image and display in browser tab.
   */
  exportGraph(): void {

    const extents = this.getExtents();

    // empty graph
    if (!extents) {
      this.notify.warn(this.translocoService.translate('EDITOR.exportNoContent'));
      return;
    }

    const { left, right, top, bottom, currentTransform } = extents;
    const padding = 10;

    // position in top left corner
    this.zoomHandle.call(this.zoomBehavior.transform, d3.zoomIdentity.translate(-left + padding, -top + padding));

    // copy node tree
    const svgOriginal = this.svgElement.nativeElement;
    const svgCopy = this.svgElement.nativeElement.cloneNode(true);

    D3jsUtils.copyInlineStyles(svgOriginal.childNodes, svgCopy.childNodes);

    svgCopy.setAttribute('width', right - left + 2 * padding);
    svgCopy.setAttribute('height', bottom - top + 2 * padding);

    // reset transform
    this.zoomHandle.call(this.zoomBehavior.transform, currentTransform);

    // open new tab
    const tab = window.open('', '_blank').document;

    // compose tab html
    tab.head.innerHTML = '<title>Export graph</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" crossorigin="anonymous">';
    tab.body.innerHTML = '<div><a class="m-2 btn btn-sm btn-outline-primary disabled" href="#" download="graph-export">Download PNG file</a></div><div class="m-2 p-2 border border-secondary d-flex justify-content-center align-items-center" style="width: 400px; height: 300px"><div class="spinner-border text-secondary"></div></div>';

    // send svg to backend for conversion
    this.graphService.convert(svgCopy.outerHTML)
      .subscribe(response => {
        // update tab html
        tab.querySelector('div.d-flex').remove();

        const imageUrl = window.URL.createObjectURL(response);

        const link = tab.querySelector('a');
        link.href = imageUrl;
        link.classList.remove('disabled');

        const img = new Image();
        img.src = imageUrl;
        img.classList.add('m-2', 'border', 'border-secondary');
        tab.body.append(img);
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
