import { Component } from '@angular/core';
import {  SafeHtml } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * Modal component that displays a guideline's full text.
 */
@Component({
  selector: 'guide-modal',
  templateUrl: './guide-modal.component.html',
  styleUrls: ['./guide-modal.component.sass']
})
export class GuideModalComponent {

  data: SafeHtml = '';
  title = '';
  // @ts-ignore
  icon: IconProp = 'question-circle-regular';

  constructor(
    private modalRef: BsModalRef
  ) { }

  close(): void {

    this.modalRef.hide();

  }

}
