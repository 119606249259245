import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import TextHighlighter from 'text-highlighter';
import { OrderBySnippetPipe } from '@shared/pipes/order-by-snippet.pipe';
import { TextSnippet } from '@data/schema/text-snippet';

@Component({
  selector: 'app-highlighter-passive',
  templateUrl: './highlighter-passive.component.html',
  styleUrls: ['./highlighter-passive.component.sass']
})
export class HighlighterPassiveComponent implements AfterViewInit {

  @ViewChild('highlightContainer', {static: true}) container: ElementRef;

  @Input() value = '';
  @Input() snippets: (TextSnippet|{snippet: TextSnippet})[] = [];

  constructor(
    private renderer: Renderer2,
    private orderBySnippet: OrderBySnippetPipe
  ) { }

  ngAfterViewInit(): void {

    const highlighter = new TextHighlighter(this.container.nativeElement, {
      onBeforeHighlight: () => false
    });

    // cleanup
    // highlighter.removeHighlights();

    // retrieve and order snippets
    const isTextSnippet = (value: TextSnippet|{snippet: TextSnippet}): value is TextSnippet => {
      return !value.hasOwnProperty('snippet');
    };
    let snippets = this.snippets.map<TextSnippet>(s => isTextSnippet(s) ? s : s.snippet);
    snippets = this.orderBySnippet.transform(snippets);

    // build JSON to deserialize highlights
    const serialized = [];
    snippets.forEach(s => {
      serialized.push([s.wrapper, s.text, s.path, s.offset, s.length]);
    });
    highlighter.deserializeHighlights(JSON.stringify(serialized));

    // prepare chunks
    const hls = highlighter.getHighlights({grouped: true});
    hls.forEach(h => {
      h.chunks.forEach(chunk => {
        this.renderer.addClass(chunk, 'stored');
      });
    });

  }

}
