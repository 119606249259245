import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { GraphUpdates, NodeData } from '@data/schema/graph';

@Injectable({
  providedIn: 'root'
})
export class GraphMessageService {

  nodesInUse$ = new ReplaySubject<{node: NodeData, inUse: boolean}>();
  hoverNode$ = new Subject<{nodeId: string, hover: boolean}>();
  update$ = new Subject<GraphUpdates>();

}
