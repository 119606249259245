<div class="infobox" *ngIf="box; else tplContent">

  <div class="infobox-legend"><fa-icon icon="question-circle-regular"></fa-icon> {{ box }}</div>

  <ng-container *ngTemplateOutlet="tplContent"></ng-container>

  <div class="expand-btn" *ngIf="showExpandBtn" (click)="expand()">Weiterlesen...</div>

</div>

<ng-template #tplContent>
  <div [innerHTML]="content"></div>
</ng-template>
