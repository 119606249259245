import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {SharedModule} from '@shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


@NgModule({
  declarations: [
    HomeComponent,
    ImprintComponent,
    LoginComponent,
    RegisterComponent,
    PrivacyComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PublicModule { }
