import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Draggable } from 'ng-drag-drop';
import { GraphMessageService } from '@shared/services/graph-message.service';
import { Finding } from '@data/schema/finding';

/**
 * This directive enhances drag behavior of ng-drag-drop.
 * If the finding is already used in the graph, drag is disabled.
 */
@Directive({
  selector: 'finding[findingDrag]'
})
export class FindingDragDirective implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @Input() data: Finding;

  constructor(
    private graphMessageService: GraphMessageService,
    private draggable: Draggable
  ) { }

  ngOnInit() {

    this.draggable.dragData = this.data;

    this.graphMessageService.nodesInUse$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(value => value.node.id === this.data.id)
      )
      .subscribe(value => {
        this.draggable.dragEnabled = !value.inUse;
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
