<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button class="close" (click)="close()">&times;</button>
</div>

<div class="modal-body">

  <div class="file-drop-area"
       ng2FileDrop
       [uploader]="uploader"
       (fileOver)="hasFileOverDropZone = $event"
       [class.file-over]="hasFileOverDropZone"
       (click)="openFileDialog()">
    <input type="file" ng2FileSelect [uploader]="uploader" (click)="$event.stopPropagation()"/>
    <div class="display-4">
      <fa-icon icon="file-upload"></fa-icon> Datei ablegen<br>
      <fa-icon icon="hand-pointer"></fa-icon> Öffnen-Dialog
    </div>
  </div>

  <div></div>

  <div *ngIf="fileItem" class="panel mt-3">
    <div class="row justify-content-center">
      {{fileItem.file.name}} &ndash; {{fileItem.file.size / 1024 | number:'1.0-0' }} KB
    </div>
    <div class="row justify-content-center mt-2">
      <button class="btn btn-primary"
              *ngIf="!fileItem.isUploading && !fileItem.isUploaded && !fileItem.isSuccess && !fileItem.isError"
              (click)="uploader.uploadAll()"><fa-icon icon="cloud-upload-alt"></fa-icon> Upload</button>
      <div class="progress" *ngIf="fileItem.isUploading">
        <div class="progress-bar" [ngStyle]="{width: fileItem.progress + '%'}"></div>
      </div>
      <div *ngIf="fileItem.progress === 100 && !fileItem.isSuccess && !fileItem.isError">
        <fa-icon icon="spinner"></fa-icon> Verarbeitung...
      </div>
      <div class="text-success" *ngIf="fileItem.isSuccess">
        <fa-icon icon="check-circle"></fa-icon> Erfolgreich!
      </div>
      <div class="text-danger" *ngIf="fileItem.isError">
        <fa-icon icon="exclamation-circle"></fa-icon> Error!
      </div>
    </div>
  </div>

</div>
