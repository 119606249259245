import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GuideModalComponent } from '@shared/components/guide-modal/guide-modal.component';

/**
 * Component loading and displaying guideline text from ID.
 * Optionally, the content is rendered in a box and can be shortened to a certain number of paragraphs.
 * If shortened, the full text can be displayed in a modal.
 */
@Component({
  selector: 'guide-text[guideId]',
  templateUrl: './guide-text.component.html',
  styleUrls: ['./guide-text.component.sass']
})
export class GuideTextComponent implements OnInit {

  @Input() guideId = '';
  @Input() box = '';
  @Input() paragraphs = -1;

  content = '';
  showExpandBtn = false;

  private data = '';

  constructor(
    private modalService: BsModalService
  ) { }

  async ngOnInit(): Promise<void> {

    if (!this.guideId) {
      this.content = '<span class="text-danger text-monospace">guide-text: No guideline ID set!</span>';
      return;
    }

    // get guideline html data
    const response = await fetch(`data/guidelines/${this.guideId}.html`);
    this.data = await response.text();

    // slice after number of paragraphs
    if (this.paragraphs > -1) {

      const matches = new RegExp(`(?:<p>.*?(?!<p>)<\/p>){${this.paragraphs}}`).exec(this.data);
      this.content = matches[0];
      this.showExpandBtn = this.content.length < this.data.length;

    } else {

      this.content = this.data;

    }

  }

  /**
   * Open modal to read the full text.
   */
  expand(): void {

    this.modalService.show(GuideModalComponent, {
      class: 'modal-lg',
      initialState: {
        data: this.data,
        title: this.box
      }
    });

  }

}
