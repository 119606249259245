import {Component, OnInit, Input, TemplateRef, Injector, OnDestroy} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {InjectedSubject} from '@shared/injection-tokens';


@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.sass']
})

export class ModalContentComponent implements OnInit, OnDestroy {

  @Input() buttonTitle: string;
  @Input() modalTitle: string;
  @Input() contentComponent;

  contentInjector;
  close$ = new Subject<void>();
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private injector: Injector
  ) {

    this.contentInjector = Injector.create({providers: [{provide: InjectedSubject, deps: [], useValue: this.close$}], parent: injector});

  }

  openModal(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-xl' }));

  }

  ngOnInit() {
    this.close$.subscribe(() => {
      this.modalRef.hide();
    });
  }

  ngOnDestroy(): void {
    this.close$.complete();
  }

}

