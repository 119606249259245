<tabset>

  <tab *ngIf="source?.phenomenon" heading="Phenomenon">
    {{ source.phenomenon.value }}
    <hr/>
    <div *ngIf="source.phenomenon.reason" class="d-flex">
      <b class="mr-2">Reason:</b>
      <span class="flex-grow-1">{{ source.phenomenon.reason }}</span>
    </div>
    <div class="mt-2">
      <button class="btn btn-sm btn-outline-secondary" (click)="followLink('phenomenon')">
        <fa-icon icon="external-link-alt"></fa-icon> Go to phenomenon
      </button>
    </div>
  </tab>

  <tab *ngIf="source?.gist" heading="Key message">
    {{ source.gist.value }}
    <hr/>
    <div class="mt-2">
      <button class="btn btn-sm btn-outline-secondary" (click)="followLink('gist')">
        <fa-icon icon="external-link-alt"></fa-icon> Go to key message
      </button>
    </div>
  </tab>

  <tab *ngIf="source?.segment" heading="Segment">
    {{ source.segment.value }}
    <hr/>
    <div class="d-flex">
      <b class="mr-2">Document:</b>
      <span class="flex-grow-1">
        {{ source.document.title }}<br/><small>{{ source.document.originalFile }}</small>
      </span>
    </div>
    <div class="mt-2">
      <button class="btn btn-sm btn-outline-secondary" (click)="followLink('segment')">
        <fa-icon icon="external-link-alt"></fa-icon> Go to text segment
      </button>
    </div>
  </tab>

  <tab *ngIf="source?.targetVariable" heading="Target variable">
    {{ source.targetVariable.value }}
    <hr/>
    <div class="d-flex">
      <b class="mr-2">Reason:</b>
      <span class="flex-grow-1">{{ source.targetVariable.reason }}</span>
    </div>
    <div class="mt-2">
      <button class="btn btn-sm btn-outline-secondary" (click)="followLink('target')">
        <fa-icon icon="external-link-alt"></fa-icon> Go to target variable
      </button>
    </div>
  </tab>

  <tab *ngIf="source?.latentVariable" heading="Latent variable">
    {{ source.latentVariable.value }}
    <hr/>
    <div class="d-flex">
      <b class="mr-2">Reason:</b>
      <span class="flex-grow-1">{{ source.latentVariable.reason }}</span>
    </div>
    <div class="mt-2">
      <button class="btn btn-sm btn-outline-secondary" (click)="followLink('latent')">
        <fa-icon icon="external-link-alt"></fa-icon> Go to latent variable
      </button>
    </div>
  </tab>

  <tab *ngIf="emptyType" heading="Empty type">
    <i>No direct source.</i>
    <div class="mt-2">
      <button class="btn btn-sm btn-outline-secondary" (click)="followLink(emptyType)">
        <fa-icon icon="external-link-alt"></fa-icon> Go to latent variable
      </button>
    </div>
  </tab>

</tabset>
