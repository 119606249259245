import { GraphNode } from './graph-node';
import { GraphLink } from './graph-link';
import { GraphMergeNode } from './graph-merge-node';

export class GraphManager<N extends GraphNode = GraphNode, L extends GraphLink = GraphLink> {

  nodes: GraphMergeNode<N>[] = [];
  links: L[] = [];

  constructor(nodes: N[] = [], links: L[] = []) {

    this.addLink(...links);
    this.addNode(...nodes);

  }

  /**
   * Add one or more links to manager.
   */
  addLink(...links: L[]): void {

    this.links.push(...links);

  }

  /**
   * Remove link from manager.
   * @param linkOrId - Pass the link object or the link id.
   */
  removeLink(linkOrId: GraphLink | string): L | undefined {

    const index = this.links.findIndex(link => link.id === (linkOrId instanceof GraphLink ? linkOrId.id : linkOrId));

    if (index > -1) {
      return this.links.splice(index, 1)[0];
    }

  }

  /**
   * Find link by source and target node.
   */
  findLink(node1: GraphNode, node2: GraphNode): L | undefined {

    return this.links.find(link => link.source === node1 && link.target === node2 || link.source === node2 && link.target === node1);

  }

  /**
   * Add one or more nodes to manager.
   * Merge nodes if they share the same variable.
   */
  addNode(...nodes: N[]) {

    let nodeA: N;

    do {
      nodeA = nodes.shift();
      if (nodeA) {
        for (const merged of this.nodes) {
          const match = merged.nodes
            .find(nodeB => nodeA.data.variable && nodeB.data.variable && nodeA.data.variable === nodeB.data.variable);

          if (match) {
            merged.addNode(nodeA);
            nodeA = null;
            break;
          }
        }

        if (nodeA) {
          this.nodes.push(new GraphMergeNode(nodeA));
        }
      }
    } while (nodes.length);

  }

  /**
   * Remove node from manager.
   * @param nodeOrId - Pass the node object or the node id.
   */
  removeNode(nodeOrId: GraphNode | string): N | undefined {

    const merged = this.findNode(nodeOrId);

    if (merged) {
      merged.parent.removeNode(merged.node);
      if (!merged.parent.nodes.length) {
        this.nodes.splice(this.nodes.indexOf(merged.parent), 1);
      }
      return merged.node;
    }

  }

  /**
   * Find node within the manager.
   * @return Return node together mit parent MergeNode and index with this MergeNode.
   * Return `undefined` node could not be found.
   */
  findNode(nodeOrId: GraphNode | string): { parent: GraphMergeNode<N>, node: N, index: number } {

    for (const merged of this.nodes) {

      const index = merged.nodes.findIndex(node => node.id === (nodeOrId instanceof GraphNode ? nodeOrId.id : nodeOrId));

      if (index > -1) {
        return {
          parent: merged,
          node: merged.nodes[index],
          index
        };
      }

    }

    return { parent: null, node: null, index: -1 };

  }

  /**
   * Traverse graph and return all elements for that callbacks will return `true`.
   */
  findSubtree(startNode: GraphNode, nodeCallback: (node: N) => boolean, linkCallback: (link: L) => boolean): (N|L)[] {

    const subtreeElements: (N|L)[] = [];

    const searchNodes = (node: N) => {

      // skip if already included
      if (subtreeElements.includes(node)) { return; }

      subtreeElements.push(node);

      const attachedLinks = this.links.filter(link => link.source === node || link.target === node);
      attachedLinks.forEach(link => {
        searchLinks(link);
      });

    };

    const searchLinks = (link: L) => {

      // skip if link fails check
      if (!linkCallback(link)) { return; }

      // skip if already included
      if (subtreeElements.includes(link)) { return; }

      const attachedNodes = [this.findNode(link.source), this.findNode(link.target)];

      // if both source and target are included, then link is also included
      if (attachedNodes.every(an => nodeCallback(an.node))) {

        subtreeElements.push(link);
        attachedNodes.forEach(an => {
          searchNodes(an.node);
        });

      }

    };

    // test if start node is part of manager
    const { node: start } = this.findNode(startNode);

    if (start && nodeCallback(start)) {
      searchNodes(start);
    }

    return subtreeElements;

  }

}
