import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBySnippet'
})
export class OrderBySnippetPipe implements PipeTransform {

  transform<T>(value: T[], args?: string): T[] {

    if (value === null) { return []; }

    const output = [].concat(value);

    output.sort((a, b) => {
      const aSnippet = args ? resolve(args, a) : a;
      const bSnippet = args ? resolve(args, b) : b;

      return compare(Array.isArray(aSnippet) ? aSnippet[0] : aSnippet, Array.isArray(bSnippet) ? bSnippet[0] : bSnippet);
    });

    return output;

  }

}

function compare(a, b) {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  const aPath = a.path.split(':');
  const bPath = b.path.split(':');
  const len = Math.min(aPath.length, bPath.length);
  let i = 0;

  while (i < len) {
    if (aPath[i] !== bPath[i]) {
      return aPath[i] - bPath[i];
    } else {
      i++;
    }
  }

  if (aPath.length === bPath.length) {
    return a.offset - b.offset;
  } else {
    return a.length - b.length;
  }
}

function resolve(path, obj) {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
}
