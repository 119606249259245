import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { Restangular, RestCollection, RestElement } from 'ngx-restangular-typed';
import { NotificationsService } from 'angular2-notifications';
import { RouteParserService } from '@shared/services/route-parser.service';
import { Finding, RestFinding } from '../schema/finding';

@Injectable({
  providedIn: 'root'
})
export class FindingService {

  constructor(
    private rest: Restangular,
    private routeParser: RouteParserService,
    private notify: NotificationsService
  ) { }

  base(): RestElement {
    return this.rest
      .one('studies', this.routeParser.getParam('studyId'));
  }

  restangularize(element: Finding): void {
    this.rest.restangularizeElement(this.base(), element, 'findings');
  }

  query(queryParams?: {type: string|string[], segmentId?: string}): Observable<RestCollection<Finding>> {
    return this.base()
      .all<Finding>('findings')
      .getList(queryParams)
      .pipe(
        catchError(err => {
          console.error(err);
          this.notify.error('Error while requesting findings', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  get(id: string): Observable<RestFinding> {
    return this.base()
      .one<Finding>('findings', id)
      .get();
  }

  save(postData): Observable<RestFinding> {
    return this.base()
      .all('findings')
      .post(postData)
      .pipe(
        catchError(err => {
          console.error(err);
          this.notify.error('Speichern fehlgeschlagen!', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  queryVariable(token?: string): Observable<string[]> {
    return this.base()
      .all('findings')
      .all<{variable: string}>('variables')
      .getList({search: token})
      .pipe(
        map(value => value.plain().map(v => v.variable))
      );
  }

}
