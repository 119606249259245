import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.sass']
})
export class PrivacyComponent implements OnInit {

  constructor(
    private titleService: Title,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {

    this.translocoService.selectTranslate('privacyPolicy')
      .pipe(take(1))
      .subscribe(value => {
        this.titleService.setTitle(value + ' – ReZA');
      });

  }

}
