<!--<div class="graph-editor-controls">-->
<!--  <div class="controls">-->
<!--    <div class="form-check form-check-inline">-->
<!--      <input class="form-check-input" type="checkbox" id="checkShortPaths"/>-->
<!--      <label class="form-check-label" for="checkShortPaths">Pfade kürzen</label>-->
<!--    </div>-->
<!--  </div>-->

<!--  <slide-button class="btn btn-sm btn-primary"><fa-icon icon="pencil-alt"></fa-icon> Editieren</slide-button>-->
<!--</div>-->

<div class="svg-container">

  <div class="graph-status-message" *transloco="let t; read: 'EDITOR'">
    <div class="lead" *ngIf="graphStatus === 1">{{ t('loading') }}...</div>
    <div class="lead" *ngIf="graphStatus === 2">{{ t('noRules') }}</div>
  </div>

  <svg #svgElement>
    <defs>
      <filter id="errorBlur" x="-50%" y="-50%" width="200%" height="200%">
        <feFlood flood-color="#ed5922" result="base"/>
        <feMorphology in="SourceGraphic" operator="dilate" radius="5" result="bigger"/>
        <feComposite in="base" in2="bigger" operator="in" result="drop"/>
        <feGaussianBlur in="drop" stdDeviation="3" result="blur"/>
        <feBlend in="SourceGraphic" in2="blur" mode="normal"/>
      </filter>
    </defs>
    <rect class="drag-handle"
          width="100" height="100"
          droppable
          (onDrop)="onDragDrop($event)"
          (onDragEnter)="onDragEnter()"
          (onDragOver)="onDragOver($event)"
          (onDragLeave)="onDragLeave()"
          dragHintClass="dragHint"
          dragOverClass="dragOver">
    </rect>
    <g></g>
  </svg>

  <div class="graph-editor-controls" *transloco="let t, read: 'EDITOR'">
    <button class="btn btn-outline-secondary" [title]="t('zoomOut')" (click)="zoomToFit()">
      <fa-icon icon="expand"></fa-icon>
    </button>
    <button class="btn btn-outline-secondary" [title]="t('zoomIn')" (click)="zoomOriginalSize()">
      <fa-icon icon="compress"></fa-icon>
    </button>
    <button class="btn btn-outline-secondary" [title]="t('export')" (click)="exportGraph()">
      <fa-icon icon="download"></fa-icon>
    </button>
  </div>

  <ul class="graph-legend list-unstyled" *ngIf="!filterByType">
    <ng-container *transloco="let t">
      <li class="target"><span>{{ t('TARGET.variable') }}</span></li>
      <li class="explicit"><span>{{ t('FINDING.explicit') }}</span></li>
      <li class="implicit"><span>{{ t('FINDING.implicit') }}</span></li>
      <li class="latent"><span>{{ t('FINDING.latent') }}</span></li>
      <li class="if"><span>{{ t('EDITOR.ifConnection') }}</span></li>
      <li class="then"><span>{{ t('EDITOR.thenConnection') }}</span></li>
    </ng-container>
  </ul>

  <graph-context-menu #contextmenu></graph-context-menu>
  <graph-source-popover #sourcePopover></graph-source-popover>

</div>
