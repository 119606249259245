import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { Restangular, RestCollection, RestElement } from 'ngx-restangular-typed';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RouteParserService } from '@shared/services/route-parser.service';
import { LinkData, NodeData, RestGraphLink, RestGraphNode, GraphUpdates } from '../schema/graph';
import { GraphNode } from '@shared/utils/graph-node';
import { GraphLink } from '@shared/utils/graph-link';


@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(
    private rest: Restangular,
    private httpClient: HttpClient,
    private routeParser: RouteParserService,
    private notify: NotificationsService
  ) { }

  base(): RestElement {
    return this.rest
      .one('studies', this.routeParser.getParam('studyId'))
      .one('graph');
  }

  restangularizeNode(element: NodeData): void {
    this.rest.restangularizeElement(this.base(), element, 'nodes');
  }

  restangularizeLink(element: LinkData): void {
    this.rest.restangularizeElement(this.base(), element, 'links');
  }

  /**
   * Query nodes and links of rule graph.
   */
  query(queryParams?: {type: string}): Observable<{nodes: RestGraphNode[], links: RestGraphLink[]}> {
    const ruleElement = this.base();
    const nodes$ = ruleElement.all<NodeData>('nodes').getList(queryParams);
    const links$ = ruleElement.all<any>('links').getList(queryParams);

    return combineLatest([nodes$, links$])
      .pipe(
        map(result => {
          // init node and link classes, reference nodes in links
          const nodes: RestGraphNode[] = result[0].map(node => new GraphNode(node) as RestGraphNode);

          const links: RestGraphLink[] = result[1].map(link => {
            link.source = nodes.find(node => node.id === link.source);
            link.target = nodes.find(node => node.id === link.target);
            return new GraphLink(link) as RestGraphLink;
          });

          return {nodes, links};
        }),
        catchError(err => {
          console.error(err);
          this.notify.error('Error while requesting nodes and links', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  queryNodes(): Observable<RestCollection<NodeData>> {
    return this.base()
      .all<NodeData>('nodes')
      .getList()
      .pipe(
        catchError(err => {
          console.error(err);
          this.notify.error('Error while requesting nodes', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  queryLinks(): Observable<RestCollection<LinkData>> {
    return this.base()
      .all<LinkData>('links')
      .getList()
      .pipe(
        catchError(err => {
          console.error(err);
          this.notify.error('Error while requesting links', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  /**
   * Save finding as part of the rule graph.
   * @param findingId - Id of finding
   * @param x - Initial x position of resulting graph node
   * @param y - Initial y position of resulting graph node
   */
  saveNode(findingId: string, x = 0, y = 0): Observable<RestGraphNode> {
    return this.base()
      .all('nodes')
      .post<NodeData>({
        findingId,
        x,
        y
      })
      .pipe(
        map(value => new GraphNode(value) as RestGraphNode),
        catchError(err => {
          console.error(err);
          this.notify.error('Speichern fehlgeschlagen!', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  /**
   * Save link between source and target in rule graph.
   */
  saveLink(source: GraphNode, target: GraphNode): Observable<RestGraphLink> {
    return this.base()
      .all('links')
      .post<LinkData>({
        sourceId: source.id,
        targetId: target.id,
      })
      .pipe(
        map((value) => {
          value.source = source;
          value.target = target;
          return new GraphLink(value) as RestGraphLink;
        }),
        catchError(err => {
          console.error(err);
          this.notify.error('Speichern fehlgeschlagen!', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  /**
   * Save link between node and link. The existing link is split into 2 links.
   * The new condition node combines all 3 new links.
   */
  saveConditionNode(type: 'if'|'then', node: GraphNode, link: GraphLink, x = 0, y = 0): Observable<GraphUpdates> {
    return this.base()
      .all('conditionNodes')
      .post({
        type,
        nodeId: node.id,
        linkId: link.id,
        x,
        y
      })
      .pipe(
        map(value => {
          // turn node data into GraphNode
          value.nodesCreated = value.nodesCreated.map(nodeData => {
            this.restangularizeNode(nodeData);
            return new GraphNode(nodeData);
          });

          // turn link data into GraphLink and set references to nodes
          const availableNodes = [node, link.source, link.target].concat(value.nodesCreated);
          value.linksCreated = value.linksCreated.map(linkData => {
            this.restangularizeLink(linkData);
            linkData.source = availableNodes.find(n => n.id === linkData.source);
            linkData.target = availableNodes.find(n => n.id === linkData.target);
            return new GraphLink(linkData);
          });

          return value;
        }),
        catchError(err => {
          console.error(err);
          this.notify.error('Speichern fehlgeschlagen!', JSON.stringify(err.error || err));
          return EMPTY;
        })
      );
  }

  convert(svg: string): Observable<Blob> {
    return this.httpClient.post('api/convert/svg', { svg }, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      responseType: 'blob'
    });
  }

}
