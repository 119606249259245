import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(private modalService: BsModalService) { }

  confirm(options?: {message?: string, confirmLabel?: string, declineLabel?: string}): Promise<boolean> {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-sm modal-dialog-centered',
      initialState: options
    });

    return modalRef.content.result.toPromise();
  }

}
