import { Component } from '@angular/core';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {

  constructor(
    private translocoService: TranslocoService
  ) {

    moment.updateLocale('en', {
      calendar: {
        sameElse: 'DD MMM YYYY'
      }
    });

    // restore language specific settings
    const lang = localStorage.getItem('lang');

    if (lang) {
      translocoService.setActiveLang(lang);
      moment.locale(lang);
    } else {
      // default to de
      moment.locale('de');
    }

  }

}
