import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faInfoCircleRegular: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'info-circle-regular',
  icon: [512, 512, [], 'f041', 'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z']
};

export const faQuestionCircleRegular: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'question-circle-regular',
  icon: [512, 512, [], 'f041', 'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z']
};

export const faProcessSteps: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'process-steps',
  icon: [512, 512, [], 'f041', 'M475.4,438.9c20.2,0,36.6,16.4,36.6,36.6c0,20.2-16.4,36.6-36.6,36.6c-20.2,0-36.6-16.4-36.6-36.6C438.9,455.2,455.2,438.9,475.4,438.9z M210.3,449.2v52.4l31.4-26.2L210.3,449.2z M259.3,484.6h161.3v-18.3H259.3l2.5,2.1c3.9,3.2,4.4,9,1.2,12.9c-0.4,0.4-0.8,0.8-1.2,1.2L259.3,484.6z M36.6,0c20.2,0,36.6,16.4,36.6,36.6S56.8,73.1,36.6,73.1S0,56.8,0,36.6S16.4,0,36.6,0z M393.1,265.1c65.6,0,118.9-53.2,118.9-118.9S458.8,27.4,393.1,27.4h-79l2.5,2.1c3.9,3.2,4.4,9,1.2,12.9c-0.4,0.4-0.8,0.8-1.2,1.2l-2.5,2.1h79c55.5,0,100.6,45,100.6,100.6s-45,100.6-100.6,100.6H310c1.1,6,1.1,12.2,0,18.3H393.1z M91.4,27.4h155.4v18.3H91.4V27.4z M256,219.4c20.2,0,36.6,16.4,36.6,36.6s-16.4,36.6-36.6,36.6s-36.6-16.4-36.6-36.6S235.8,219.4,256,219.4z M265.1,62.8l31.4-26.2l-31.4-26.2V62.8z M202,265.1c-1.1-6-1.1-12.2,0-18.3h-83.1C53.2,246.9,0,300.1,0,365.7s53.2,118.9,118.9,118.9H192v-18.3h-73.1c-55.5,0-100.6-45-100.6-100.6s45-100.6,100.6-100.6H202z']
};

export const faSortDownDuotone: IconDefinition = {
  prefix: 'fad',
  iconName: 'sort-down',
  icon: [320, 512, [], 'f0dd', ['M24.05 183.05l119.1-119A23.9 23.9 0 0 1 177 64a.94.94 0 0 1 .1.1l119 119c15.1 15.1 4.4 41-17 41h-238c-21.45-.05-32.1-25.95-17.05-41.05z', 'M41.05 288.05h238c21.4 0 32.1 25.9 17 41l-119 119a23.9 23.9 0 0 1-33.8.1l-.1-.1-119.1-119c-15.05-15.05-4.4-41 17-41z']]
};

export const faLightbulbOn: IconDefinition = {
  prefix: 'far',
  iconName: 'lightbulb-on',
  icon: [640, 512, [], 'f672', 'M 112 192 a 24 24 0 0 0 -24 -24 H 24 a 24 24 0 0 0 0 48 H 88 A 24 24 0 0 0 112 192 Z m -4.92 95.22 l -55.42 32 a 24 24 0 1 0 24 41.56 l 55.42 -32 a 24 24 0 0 0 -24 -41.56 Z m 24 -232 l -55.42 -32 a 24 24 0 1 0 -24 41.56 l 55.42 32 a 24 24 0 1 0 24 -41.56 Z M 520.94 100 a 23.8 23.8 0 0 0 12 -3.22 l 55.42 -32 a 24 24 0 0 0 -24 -41.56 l -55.42 32 a 24 24 0 0 0 12 44.78 Z M 616 168 H 552 a 24 24 0 0 0 0 48 h 64 a 24 24 0 0 0 0 -48 Z M 588.34 319.23 l -55.42 -32 a 24 24 0 1 0 -24 41.56 l 55.42 32 a 24 24 0 0 0 24 -41.56 Z M 320 0 C 217.72 0 144 83 144 176 a 175 175 0 0 0 43.56 115.78 c 16.63 19 42.75 58.8 52.41 92.16 V 384 h 48 v -0.12 a 47.67 47.67 0 0 0 -2.13 -14.07 C 280.25 352 263 305.06 223.66 260.15 A 127.48 127.48 0 0 1 192.06 176 C 191.84 102.36 251.72 48 320 48 a 127.91 127.91 0 0 1 96.34 212.15 c -39.09 44.61 -56.4 91.47 -62.09 109.46 A 56.78 56.78 0 0 0 352 383.92 V 384 h 48 V 384 c 9.69 -33.37 35.78 -73.18 52.41 -92.15 A 175.93 175.93 0 0 0 320 0 Z m 0 80 a 96.11 96.11 0 0 0 -96 96 a 16 16 0 0 0 32 0 a 64.08 64.08 0 0 1 64 -64 a 16 16 0 0 0 0 -32 Z M 240.06 459.19 a 16 16 0 0 0 2.69 8.84 l 24.5 36.83 A 16 16 0 0 0 280.56 512 h 78.85 a 16 16 0 0 0 13.34 -7.14 L 397.25 468 a 16.2 16.2 0 0 0 2.69 -8.84 L 400 416 H 240 Z']
};

export const faArrowRightSolid: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'arrow-right-solid',
  icon: [300, 240, [], 'f041', 'M211,25.8c-4.3-4.6-11.5-4.8-16.1-0.5s-4.8,11.5-0.5,16.1l63.5,67.2H11.4C5.1,108.6,0,113.7,0,120s5.1,11.4,11.4,11.4h246.6 l-63.5,67.2c-2,2.1-3.1,4.9-3.1,7.8c0,6.3,5.1,11.4,11.4,11.4c3.1,0,6.1-1.3,8.3-3.6l89-94.2L211,25.8z']
};

export const faArrowRightDashed: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'arrow-right-dashed',
  icon: [300, 240, [], 'f041', 'M173,120c0,6.3-5.1,11.4-11.4,11.4h-50.1c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4h50.1C167.9,108.6,173,113.7,173,120 z M61.5,108.6H11.4C5.1,108.6,0,113.7,0,120s5.1,11.4,11.4,11.4h50.1h0c6.3,0,11.4-5.1,11.4-11.4S67.7,108.6,61.5,108.6z M211,25.8 c-4.3-4.6-11.5-4.8-16.1-0.5s-4.8,11.5-0.5,16.1l63.5,67.2h-46.3c-6.3,0-11.4,5.1-11.4,11.4s5.1,11.4,11.4,11.4h46.3l-63.5,67.2 c-2,2.1-3.1,4.9-3.1,7.8c0,6.3,5.1,11.4,11.4,11.4c3.1,0,6.1-1.3,8.3-3.6l89-94.2L211,25.8z']
};

export const faSource: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'source',
  icon: [300, 240, [], 'f041', 'M300,120c-28.5-24.2-82.1-69.5-84.6-71.7l0,49.4l-80.7,0l0-34.4c0-28.1-23.1-51.2-51.1-51.2L0,12.1l0,33.3l83.5,0c10.1,0,17.7,7.8,17.7,17.9l0,113.3c0,10.1-7.6,17.9-17.7,17.9l-83.5,0l0,33.3l83.5,0c28.1,0,51.1-23.2,51.1-51.3v-34.4l80.7,0l0,49.4C217.9,189.5,271.5,144.2,300,120z']
};

export const faBCircle: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'b-circle',
  icon: [512, 512, [], 'f041', 'M256,8C119,8,8,119.1,8,256c0,137,111,248,248,248s248-111,248-248C504,119.1,393,8,256,8z M256,456c-110.5,0-200-89.4-200-200c0-110.5,89.5-200,200-200c110.5,0,200,89.5,200,200C456,366.5,366.6,456,256,456z M379.2,313.2c0,24.6-7.4,45.8-22.3,63.5c-15.7,18.6-35.7,27.8-59.9,27.8c-9.1,0-22.7-0.2-40.9-0.6c-18.2-0.4-31.8-0.6-40.9-0.6c-6.7,0-16.7,0.3-30.1,0.8c-13.4,0.6-23.4,0.8-30.1,0.8c-3.5,0-5.3-1.6-5.3-4.9c0-15.6,0.7-39,2.2-70.2s2.2-54.6,2.2-70.2c0-16.4-0.7-41.1-2.2-74s-2.2-57.6-2.2-74c0-3.1,1.8-4.7,5.3-4.7c12.6,0,31.6,0.1,56.9,0.2c25.3,0.1,44.4,0.2,57.1,0.2c29.7,0,53.5,5.5,71.2,16.6c21.5,13.5,32.3,34.3,32.3,62.7c0,9.8-2.8,19.9-8.5,30.5c-5.7,10.6-12.5,18.5-20.6,23.9c-4.1,2.7-6.2,4.5-6.2,5.3s2.1,2.4,6.2,4.7c10.8,5.9,19.4,14.7,25.9,26.2C375.9,288.8,379.2,300.8,379.2,313.2z M319.5,191.3c0-20-8.9-33.4-26.8-40.4c-10.8-4.1-28.4-6.2-52.9-6.2c-23.8,0-35.7,1.8-35.7,5.3c0,4.7-0.2,11.7-0.6,21s-0.6,16.4-0.6,21.2c0,3.3,0.1,8.3,0.2,15.1c0.1,6.8,0.2,11.9,0.2,15.3c0,4.1,16,6.2,48,6.2C296.8,228.9,319.5,216.4,319.5,191.3z M325.3,311.1c0-17.3-7.7-29-23.2-35c-9.8-4-25-5.9-45.7-5.9c-35.7,0-53.5,1.9-53.5,5.7v34.8c0,5.1,0.1,12.9,0.4,23.4c0.3,10.5,0.4,18.3,0.4,23.6c0,1.4,4.5,2.5,13.6,3.2c9.1,0.7,22.5,1.1,40.4,1.1c22.4,0,38.4-2.9,48.2-8.7C318.8,345.5,325.3,331.5,325.3,311.1z']
};

export const faFCircle: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'f-circle',
  icon: [512, 512, [], 'f041', 'M256,8C119,8,8,119.1,8,256c0,137,111,248,248,248s248-111,248-248C504,119.1,393,8,256,8z M256,456c-110.5,0-200-89.4-200-200c0-110.5,89.5-200,200-200s200,89.5,200,200S366.6,456,256,456z M363.9,151.3c0,2.7-1.5,4-4.5,4c-11,0-29.2-0.6-54.4-1.9c-23.5-1.1-41.6-1.6-54.4-1.3l-15.5,0.2c-9.9,0.1-15.2,0.4-15.7,0.6c-2.8,1.1-4.2,5.6-4.2,13.4v51.2c0,9.6,2.1,14.4,6.2,14.4c13.7,0,34.4-0.6,62.1-1.9c27.7-1.3,48.4-1.9,62.1-1.9c3,0,4.5,1.1,4.5,3.4c0,2.4-0.2,6-0.5,10.7c-0.4,4.7-0.5,8.3-0.5,10.7c0,2.5,0.1,6.4,0.4,11.6c0.3,5.2,0.4,9,0.4,11.6c0,1.7-1.5,2.5-4.5,2.5c-9.8,0-24.6-0.3-44.5-0.8c-19.9-0.6-34.8-0.8-44.7-0.8c-18.3,0-28.6,0.1-31,0.4c-6.4,0.7-9.6,3.3-9.6,7.9c0,12.7,0.4,31.9,1.1,57.3c0.7,25.5,1.1,44.6,1.1,57.3c0,4.2-1.7,6.4-5.1,6.4c-2.8,0-7-0.3-12.6-1c-5.6-0.6-9.9-1-12.8-1c-2.4,0-6.1,0.2-11,0.6c-5,0.4-8.6,0.6-11,0.6c-3.5,0-5.3-1.6-5.3-4.7c0-15.7,0.7-39.4,2-70.9c1.3-31.6,2-55.2,2-70.9c0-14.6-1.5-62-4.5-142.3v-2.8c-0.1-3,1.6-4.7,5.3-5.1c1.1,0,10.1,0.2,27,0.6c22.2,0.4,42.8,0.5,61.8,0.2c25.6-0.3,58.3-1.7,97.9-4.2c5.4-0.4,8.4,1.9,9.1,7c-0.1,3.8,0.1,9.6,0.8,17.4C363.1,141,363.9,148.1,363.9,151.3z']
};

export const faPencilCircle: IconDefinition = {
  prefix: 'fas',
  // @ts-ignore
  iconName: 'pencil-circle',
  icon: [512, 512, [], 'f041', 'M256,8C119,8,8,119.1,8,256c0,137,111,248,248,248s248-111,248-248C504,119.1,393,8,256,8z M256,456c-110.5,0-200-89.4-200-200c0-110.5,89.5-200,200-200s200,89.5,200,200S366.6,456,256,456z M382.2,196.5l-24.1,24.1c-2.5,2.5-6.4,2.5-8.9,0l-57.9-57.9c-2.5-2.5-2.5-6.4,0-8.9l24.1-24.1c9.8-9.8,25.6-9.8,35.4,0l31.4,31.4C392,170.9,392,186.7,382.2,196.5z M270.7,174.5l-137,137l-11.1,63.4c-1.5,8.6,5.9,16,14.5,14.5l63.4-11.1l137-137c2.5-2.5,2.5-6.4,0-8.9l-57.9-57.9C277.1,172,273.2,172,270.7,174.5L270.7,174.5z M187.2,299.7c-2.9-2.9-2.9-7.5,0-10.3l80.4-80.4c2.9-2.9,7.5-2.9,10.3,0c2.9,2.9,2.9,7.5,0,10.3l-80.4,80.4C194.6,302.6,190,302.6,187.2,299.7L187.2,299.7z M168.3,343.6h25v18.9l-33.7,5.9l-16.2-16.2l5.9-33.7h18.9V343.6z']
};
