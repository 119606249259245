<ng-container *transloco="let t">

  <div class="finding-header">

    <meta-tooltip [meta]="data?.meta" [hidden]="inEditMode"></meta-tooltip>

    <span *ngIf="editable">
      <fa-icon icon="check"
               class="icon-btn text-success"
               [title]="t('save')"
               *ngIf="inEditMode"
               (click)="save()"></fa-icon>
      <fa-icon icon="pencil-alt"
               class="icon-btn"
               [title]="t('edit')"
               *ngIf="!inEditMode"
               (click)="enterEditMode()"></fa-icon>
      <fa-icon icon="times"
               class="icon-btn"
               [title]="t('cancel')"
               *ngIf="inEditMode"
               (click)="exitEditMode()"></fa-icon>
      <fa-icon icon="trash-alt"
               class="icon-btn text-danger"
               [title]="t('delete')"
               *ngIf="deletable && data && inEditMode"
               (click)="remove()"></fa-icon>
    </span>

  </div>

  <div class="finding-body">

    <div *ngIf="!inEditMode">
      <div class="variable">{{ data.variable }}</div>
      <div class="value">{{ t(data.value) }}</div>
    </div>

    <div *ngIf="inEditMode" [formGroup]="findingForm">
      <div class="variable">
        <label for="fVariable">
          {{ t('variable') }}
          <guide-button [guideId]="type === 'target' ? 'preparation-target-wording' : type === 'latent' ? 'postprocessing-latent-wording' : 'analysis-derivation-wording'"
                        title="Formulierungshinweise"
                        icon="f-circle"></guide-button>
        </label>
        <input id="fVariable"
               class="form-control form-control-sm"
               formControlName="variable"
               autocomplete="off"
               [typeahead]="variablesSource$"
               [typeaheadAsync]="true"
               [typeaheadMinLength]="3"
               container="body"
               [typeaheadOptionsLimit]="10"
               [typeaheadWaitMs]="150"/>
      </div>
      <div class="value">
        <label for="fValue">{{ t('value') }}</label>
        <select id="fValue" class="form-control form-control-sm" formControlName="value">
        <option value="" selected>{{ t('FORM.select') }}...</option>
        <option *ngFor="let val of findingValues" [ngValue]="val" [disabled]="!val">
          {{ t(val || '───────────────') }}
        </option>
        </select>
      </div>
    </div>

  </div>

</ng-container>
