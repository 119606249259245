import { Component, ElementRef, HostBinding } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IContextMenuEntry {
  label: string;
  callback: (...args) => any;
  icon?: IconProp | string;
}

@Component({
  selector: 'graph-context-menu',
  templateUrl: './graph-context-menu.component.html',
  styleUrls: ['./graph-context-menu.component.sass']
})
export class GraphContextMenuComponent {

  @HostBinding('class.d-block') private active = false;
  @HostBinding('style.top.px') private top = 10;
  @HostBinding('style.left.px') private left = 10;

  title = '';
  entries: IContextMenuEntry[] = [];

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  /**
   * Show context menu with title and entries with respect to position of mouse event.
   */
  show(title: string, entries: IContextMenuEntry[], event: MouseEvent | PointerEvent): void {

    this.title = title;
    this.entries = entries;

    this.left = -999;
    this.top = -999;

    this.active = true;

    // set position after content has been set to compute element size
    setTimeout(() => {
      const parentRect = this.element.nativeElement.parentElement.getBoundingClientRect();
      this.left = Math.min(
        event.pageX - parentRect.left,
        this.element.nativeElement.parentElement.offsetWidth - this.element.nativeElement.offsetWidth - 1
      );
      this.top = Math.min(
        event.pageY - parentRect.top,
        this.element.nativeElement.parentElement.offsetHeight - this.element.nativeElement.offsetHeight - 1
      );
    });

  }

  /**
   * Hide context menu.
   */
  hide(): void {

    this.active = false;

  }

  action(cb: (...args) => any): void {

    cb();
    this.hide();

  }

}
