import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.sass']
})
export class ConfirmModalComponent implements OnDestroy {

  message = 'Fortfahren?';
  confirmLabel = this.translocoService.translate('yes');
  declineLabel = this.translocoService.translate('no');

  result = new Subject<boolean>();

  constructor(
    private modalRef: BsModalRef,
    private translocoService: TranslocoService
  ) { }

  ngOnDestroy(): void {
    if (!this.result.isStopped) {
      this.result.next(false);
      this.result.complete();
    }
  }

  confirm() {
    this.result.next(true);
    this.result.complete();
    this.modalRef.hide();
  }

  decline() {
    this.result.next(false);
    this.result.complete();
    this.modalRef.hide();
  }

}
