import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.sass']
})
export class PanelComponent implements OnInit {

  @Input() title = 'No title';
  @Input() titleSize = 'h5';
  @Input() iconSrc: string;
  @Input() iconAlt = 'Panel Icon';
  @Input() noBorder: boolean;
  @Input() noPadding: boolean;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    // check boolean attributes
    this.noBorder = this.noBorder !== undefined;
    this.noPadding = this.noPadding !== undefined;

    this.renderer.removeAttribute(this.elementRef.nativeElement, 'title');
  }

}
