<ng-container *transloco="let t">

  <div class="phenomenon-header">

    <meta-tooltip [meta]="data?.meta" [hidden]="inEditMode"></meta-tooltip>

    <span *ngIf="editable">
      <fa-icon icon="check"
               class="icon-btn text-success"
               [title]="t('save')"
               *ngIf="inEditMode"
               (click)="save()"></fa-icon>
      <fa-icon icon="pencil-alt"
               class="icon-btn"
               [title]="t('edit')"
               *ngIf="!inEditMode"
               (click)="enterEditMode()"></fa-icon>
      <fa-icon icon="times"
               class="icon-btn"
               [title]="t('cancel')"
               *ngIf="inEditMode"
               (click)="cancel()"></fa-icon>
       <fa-icon icon="trash-alt"
                class="icon-btn text-danger"
                [title]="t('delete')"
                *ngIf="data && !inEditMode && deletable"
                (click)="remove()"></fa-icon>
    </span>

  </div>

  <div class="phenomenon-body">

    <div *ngIf="!inEditMode">
      <div>
        <label>{{ t('description') }}</label>
        <div>{{data.value}}</div>
      </div>
      <div>
        <label>{{ t('reason') }}</label>
        <div>{{data.reason}}</div>
      </div>
    </div>

    <div *ngIf="inEditMode" [formGroup]="phenomenonForm">
      <div>
        <label for="pValue">{{ t('description') }}</label>
        <textarea id="pValue" class="form-control" rows="2" formControlName="value"></textarea>
      </div>
      <div>
        <label for="pReason">{{ t('reason') }}</label>
        <textarea id="pReason" class="form-control" rows="2" formControlName="reason"></textarea>
      </div>
    </div>

  </div>

</ng-container>
