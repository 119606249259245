import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RestImplicitPhenomenon } from '@data/schema/phenomenon';
import { RestTargetVariable } from '@data/schema/target-variable';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ConfirmModalService } from '@shared/services/confirm-modal.service';
import { RestLatentVariable } from '@data/schema/latent-variable';


type RestType = RestImplicitPhenomenon | RestTargetVariable | RestLatentVariable;

@Component({
  selector: 'phenomenon-body',
  templateUrl: './phenomenon-body.component.html',
  styleUrls: ['./phenomenon-body.component.sass']
})

export class PhenomenonBodyComponent implements OnInit {

  private dataValue: RestType;
  public inEditMode = false;

  public phenomenonForm = new FormGroup({
    value: new FormControl('', Validators.required),
    reason: new FormControl('', Validators.required)
  });

  @Input() editable = true;
  @Input() deletable = false;
  @Input() service;

  @Output() dataChange = new EventEmitter<RestType>();
  @Output() saved = new EventEmitter<any>();
  @Output() removed = new EventEmitter<any>();

  @Input()
  get data(): RestType {
    return this.dataValue;
  }

  set data(val: RestType) {
    this.dataValue = val;
    this.dataChange.emit(this.dataValue);
  }

  constructor(
    private notify: NotificationsService,
    private confirmModal: ConfirmModalService
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.enterEditMode();
    }
  }

  enterEditMode() {
    this.phenomenonForm.setValue({
      value: this.data ? this.data.value : '',
      reason: this.data ? this.data.reason : ''
    });
    this.inEditMode = true;
  }

  exitEditMode() {
    if (this.phenomenonForm.valid) {
      this.inEditMode = false;
    }
  }

  save() {
    if (this.phenomenonForm.invalid) {
      this.notify.warn('Bitte beide Felder ausfüllen!');
      return;
    }

    if (!this.data) {
      this.service.save({
        value: this.phenomenonForm.value.value,
        reason: this.phenomenonForm.value.reason,
        type: 'implicit' // ToDo: Type is only used for Phenomena not Target
      }).subscribe((result: RestType) => {
        this.data = result;
        this.exitEditMode();

        this.saved.emit(this.data);
      });

    } else {
      if (this.data.value === this.phenomenonForm.value.value
        && this.data.reason === this.phenomenonForm.value.reason) {
        this.exitEditMode();
        return;
      }

      this.data.value = this.phenomenonForm.value.value;
      this.data.reason = this.phenomenonForm.value.reason;

      this.data.patch()
        .subscribe(result => {
          this.data = result;
          this.exitEditMode();
        }, err => {
          console.error(err);
          this.notify.error('Speichern fehlgeschlagen!', JSON.stringify(err.error || err));
        });
    }
  }

  cancel() {
    this.exitEditMode();

    if (!this.data) {
      this.removed.emit(this.data);
    }
  }

  async remove() {
    if (!this.deletable) {
      return;
    }
    // confirm action
    let message = 'Wollen Sie diese Zielvariable wirklich löschen?';

    if ((this.data as RestImplicitPhenomenon).type) {
      message = 'Wollen Sie dieses Phänomen wirklich löschen?';
    }

    const confirm = await this.confirmModal.confirm({message});

    if (!confirm) {
      return;
    }

    if (!this.data) {
      return;
    }

    this.data.remove()
      .subscribe(() => {
        this.data = null;
        this.removed.emit(this.data);
      }, err => {
        if (err.status === 409 && err.error.message === 'DEEPLY_CONNECTED') {
          this.notify.error('Zu tief vernetzt!', 'Dieses Element ist mit einem Befund verknüpft und kann nicht gelöscht werden!');
        } else {
          console.error(err);
          this.notify.error('Löschen fehlgeschlagen!', JSON.stringify(err.error || err));
        }
      });
  }

}
