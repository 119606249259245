<form [formGroup]="registerForm" (ngSubmit)="register()">
  <fieldset>
    <legend>Register</legend>

    <div class="form-group">
      <label for="rEmail">Email</label>
      <input id="rEmail"
             type="email"
             class="form-control"
             formControlName="email"
             required autocomplete="email"
             [class.is-invalid]="submitted && registerForm.controls.email.errors"/>
      <div class="invalid-feedback" *ngIf="submitted && registerForm.controls.email.errors">
        <span *ngIf="registerForm.controls.email.errors.required">Erforderlich!</span>
        <span *ngIf="registerForm.controls.email.errors.email">Email muss eine gültige Email-Adresse sein!</span>
      </div>
    </div>

    <div class="form-group">
      <label for="rName">Nutzername</label>
      <input id="rName"
             type="text"
             class="form-control"
             formControlName="name"
             required
             autocomplete="username"
             [class.is-invalid]="submitted && registerForm.controls.name.errors"/>
      <div class="invalid-feedback" *ngIf="submitted && registerForm.controls.name.errors">
        Erforderlich!
      </div>
    </div>

    <div class="form-group">
      <label for="rPassword">Passwort</label>
      <input id="rPassword"
             type="password"
             class="form-control"
             formControlName="password"
             required
             autocomplete="new-password"
             [class.is-invalid]="submitted && registerForm.controls.password.errors"/>
      <div class="invalid-feedback" *ngIf="submitted && registerForm.controls.password.errors">
        <span *ngIf="registerForm.controls.password.errors.required">Erforderlich!</span>
        <span *ngIf="registerForm.controls.password.errors.minlength">Passwort muss mindestens 6 Zeichen enthalten!</span>
      </div>
    </div>
  </fieldset>

  <div class="form-group">
    <button class="btn btn-primary" type="submit">Registrieren</button>
  </div>
</form>
