import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'slide-button',
  templateUrl: './slide-button.component.html',
  styleUrls: ['./slide-button.component.sass']
})
export class SlideButtonComponent implements OnInit {

  @Input() title: string;
  @HostBinding('class') @Input() type: 'previous' | 'next';

  constructor() { }

  ngOnInit() {
  }

}
