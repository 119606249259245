import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GuideModalComponent } from '@shared/components/guide-modal/guide-modal.component';

/**
 * Simple icon button that loads a guideline and
 * either opens a modal or a popover to display the data.
 */
@Component({
  selector: 'guide-button',
  templateUrl: './guide-button.component.html',
  styleUrls: ['./guide-button.component.sass']
})
export class GuideButtonComponent {

  @Input() guideId = '';
  // @ts-ignore
  @Input() icon: IconProp = 'question-circle-regular';
  @Input() @HostBinding() title = '';
  @Input() type: 'modal' | 'popover' = 'modal';

  @ViewChild('popover', { static: true }) popover: PopoverDirective;

  data: SafeHtml = '';

  constructor(
    private modalService: BsModalService,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Open modal to read the guideline.
   */
  async open(): Promise<void> {

    // get guideline html data
    if (!this.data) {
      const response = await fetch(`data/guidelines/${this.guideId}.html`);
      this.data = this.sanitizer.bypassSecurityTrustHtml(await response.text());
    }

    switch (this.type) {

      case 'modal':
        this.modalService.show(GuideModalComponent, {
          class: 'modal-lg',
          initialState: {
            title: this.title,
            data: this.data,
            icon: this.icon
          }
        });
        break;

      case 'popover':
        this.popover.show();

    }

  }

}
