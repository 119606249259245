<form [formGroup]="loginForm" (ngSubmit)="login()">
  <fieldset>
    <legend>Login</legend>

    <div class="form-group">
      <label for="iUser">Nutzername oder Email</label>
      <input id="iUser"
             type="text"
             class="form-control"
             formControlName="user"
             required
             autocomplete="username"
             [class.is-invalid]="submitted && loginForm.controls.user.errors"/>
      <div class="invalid-feedback" *ngIf="submitted && loginForm.controls.user.errors">
        Erforderlich!
      </div>
    </div>

    <div class="form-group">
      <label for="iPassword">Passwort</label>
      <input id="iPassword"
             type="password"
             class="form-control"
             formControlName="password"
             required
             autocomplete="current-password"
             [class.is-invalid]="submitted && loginForm.controls.password.errors"/>
      <div class="invalid-feedback" *ngIf="submitted && loginForm.controls.password.errors">
        Erforderlich!
      </div>
    </div>
  </fieldset>

  <div class="form-group">
    <button class="btn btn-primary" type="submit">Login</button>
  </div>
</form>
