import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { MomentModule } from 'ngx-moment';
import { FileUploadModule } from 'ng2-file-upload';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgDragDropModule } from 'ng-drag-drop';

import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { OrderBySnippetPipe } from './pipes/order-by-snippet.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FindingHoverDirective } from './directives/finding-hover.directive';
import { FindingDragDirective } from './directives/finding-drag.directive';
import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { FindingComponent } from './components/finding/finding.component';
import { SlideButtonComponent } from './components/slide-button/slide-button.component';
import { TargetModalComponent } from './components/target-modal/target-modal.component';
import { GraphEditorComponent } from './components/graph-editor/graph-editor.component';
import { PhenomenonBodyComponent } from './components/phenomenon-body/phenomenon-body.component';
import { PanelComponent } from './components/panel/panel.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { HighlighterPassiveComponent } from './components/highlighter-passive/highlighter-passive.component';
import { MetaTooltipComponent } from './components/meta-tooltip/meta-tooltip.component';
import { GraphContextMenuComponent } from './components/graph-context-menu/graph-context-menu.component';
import { GraphSourcePopoverComponent } from './components/graph-source-popover/graph-source-popover.component';

import {
  faArrowRightDashed,
  faArrowRightSolid, faBCircle, faFCircle,
  faInfoCircleRegular,
  faLightbulbOn, faPencilCircle,
  faProcessSteps, faQuestionCircleRegular,
  faSortDownDuotone, faSource
} from '@shared/icons';
import { GuideTextComponent } from './components/guide-text/guide-text.component';
import { GuideModalComponent } from './components/guide-modal/guide-modal.component';
import { GuideButtonComponent } from './components/guide-button/guide-button.component';


@NgModule({
  declarations: [
    OrderBySnippetPipe,
    FilterArrayPipe,
    FindingHoverDirective,
    FindingDragDirective,
    FileUploadModalComponent,
    ConfirmModalComponent,
    FindingComponent,
    SlideButtonComponent,
    TargetModalComponent,
    GraphEditorComponent,
    PhenomenonBodyComponent,
    ModalContentComponent,
    PanelComponent,
    HighlighterPassiveComponent,
    MetaTooltipComponent,
    GraphContextMenuComponent,
    GraphSourcePopoverComponent,
    GuideTextComponent,
    GuideModalComponent,
    GuideButtonComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    FontAwesomeModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    MomentModule,
    ReactiveFormsModule,
    NgDragDropModule.forRoot(),
    TranslocoRootModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MomentModule,
    OrderBySnippetPipe,
    FilterArrayPipe,
    FindingComponent,
    SlideButtonComponent,
    TargetModalComponent,
    GraphEditorComponent,
    GraphContextMenuComponent,
    PhenomenonBodyComponent,
    NgDragDropModule,
    ModalContentComponent,
    PopoverModule,
    PanelComponent,
    HighlighterPassiveComponent,
    MetaTooltipComponent,
    TranslocoRootModule,
    BsDropdownModule,
    FindingHoverDirective,
    FindingDragDirective,
    GuideTextComponent,
    GuideButtonComponent
  ],
  providers: [OrderBySnippetPipe]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faInfoCircleRegular, faQuestionCircleRegular, faProcessSteps, faSortDownDuotone, faLightbulbOn,
      faArrowRightSolid, faArrowRightDashed, faSource, faBCircle, faFCircle, faPencilCircle);
  }
}
